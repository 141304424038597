import { createStore } from 'vuex';
import welcome from './modules/welcome.js';
import lang from './modules/language.js';
import navbar from './modules/navbar.js';
import media from './modules/media.js';
import services from './modules/services.js';
import attractions from './modules/attractions.js';
import gallery from './modules/gallery.js';
import parc from './modules/parc.js';
import questions from './modules/questions.js';
export default createStore({
  state() {
    return {
      GOOLGE_MAP_KEY: 'AIzaSyCz2AvkzQDEZbYW-_42oWit-0W6Uhecjb8',
      isLoading: false,
    };
  },
  mutations: {
    toggleLoading(state) {
      state.isLoading = !state.isLoading;
    },
  },
  getters: {
    getMapSource(state) {
      return `https://www.google.com/maps/embed/v1/place?key=${state.GOOLGE_MAP_KEY}
        &q=Aqua+Palace,Sousse+Tunisie`;
    },
  },
  actions: {},
  modules: {
    welcome: welcome,
    lang: lang,
    navbar: navbar,
    attractions: attractions,
    services: services,
    media: media,
    gallery: gallery,
    parc: parc,
    questions: questions,
  },
});
