export const routes = [
  {
    name: 'client',
    path: '/',
    redirect: { name: 'home' },
    component: () =>
      import(/* webpackChunkName: "welcome"*/ '@/views/Client/Client.vue'),
    children: [
      {
        name: 'accueil',
        path: 'accueil',
        component: () =>
          import(
            /* webpackChunkName: "welcome"*/ '@/views/Client/Vitrine/Vitrine.vue'
          ),
        meta: {
          sitemap: {
            lastmod: '2021-03-31',
            changefreq: 'weekly',
            priority: 1.0,
          },
        },
        children: [
          {
            name: 'home',
            path: '',
            component: () =>
              import(
                /* webpackChunkName: "welcome"*/ '@/components/VitrineWelcome/VitrineWelcome.vue'
              ),
          },
          {
            name: 'media',
            path: 'media',
            component: () =>
              import(
                /* webpackChunkName: "media"*/ '@/components/VitrineMedia/VitrineMedia.vue'
              ),
            meta: {
              sitemap: {
                lastmod: '2021-03-15',
                changefreq: 'weekly',
                priority: 0.7,
              },
            },
          },
          {
            name: 'horaire',
            path: 'horaire',
            component: () =>
              import(
                /* webpackChunkName: "welcome"*/ '@/components/VitrineHoraire/VitrineHoraire.vue'
              ),
            meta: {
              sitemap: {
                lastmod: '2021-03-31',
                changefreq: 'weekly',
                priority: 0.9,
              },
            },
          },
          {
            name: 'prix',
            path: 'prix',
            component: () =>
              import(
                /* webpackChunkName: "welcome"*/ '@/components/VitrinePrix/VitrinePrix.vue'
              ),
            meta: {
              sitemap: {
                lastmod: '2021-03-31',
                changefreq: 'weekly',
                priority: 0.9,
              },
            },
          },
          {
            name: 'NoFood',
            path: 'NoFood',
            component: () =>
              import(
                /* webpackChunkName: "welcome"*/ '@/components/VitrineRules/VitrineRules.vue'
              ),
            meta: {
              sitemap: {
                lastmod: '2022-07-26',
                changefreq: 'weekly',
                priority: 0.9,
              },
            },
          },
          {
            name: 'map',
            path: 'map',
            component: () =>
              import(
                /* webpackChunkName: "welcome"*/ '@/components/VitrineMap/VitrineMap.vue'
              ),
            meta: {
              sitemap: {
                lastmod: '2021-03-15',
                changefreq: 'weekly',
                priority: 0.7,
              },
            },
          },
        ],
      },
      {
        name: 'services',
        path: 'services',
        component: () =>
          import(
            /* webpackChunkName: "services"*/ '@/views/Client/Services/Services.vue'
          ),
        meta: {
          sitemap: {
            lastmod: '2021-03-15',
            changefreq: 'weekly',
            priority: 0.4,
          },
        },
      },
      {
        name: 'service',
        path: 'service/:id',
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "services"*/ '@/views/Client/ServicePage/ServicePage.vue'
          ),
        meta: {
          sitemap: {
            slugs: [
              {
                id: '0',
                lastmod: '2021-03-31',
                changefreq: 'weekly',
                priority: 0.4,
              },
              {
                id: '1',
                lastmod: '2021-03-31',
                changefreq: 'weekly',
                priority: 0.4,
              },
              {
                id: '2',
                lastmod: '2021-03-31',
                changefreq: 'weekly',
                priority: 0.4,
              },
              {
                id: '3',
                lastmod: '2021-03-31',
                changefreq: 'weekly',
                priority: 0.4,
              },
            ],
          },
        },
      },
      {
        name: 'attractions',
        path: 'attractions',
        component: () =>
          import(
            /* webpackChunkName: "attractions"*/ '@/views/Client/Attractions/Attractions.vue'
          ),
        meta: {
          sitemap: {
            lastmod: '2021-04-12',
            changefreq: 'weekly',
            priority: 0.5,
          },
        },
      },
      {
        name: 'attraction',
        path: 'attraction/:id',
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "attractions"*/ '@/views/Client/AttractionPage/AttractionPage.vue'
          ),
        meta: {
          sitemap: {
            slugs: [
              {
                id: '0',
                lastmod: '2021-04-11',
                changefreq: 'weekly',
                priority: 0.3,
              },
              {
                id: '1',
                lastmod: '2021-04-11',
                changefreq: 'weekly',
                priority: 0.3,
              },
              {
                id: '2',
                lastmod: '2021-04-11',
                changefreq: 'weekly',
                priority: 0.3,
              },
              {
                id: '3',
                lastmod: '2021-04-11',
                changefreq: 'weekly',
                priority: 0.3,
              },
              {
                id: '4',
                lastmod: '2021-04-11',
                changefreq: 'weekly',
                priority: 0.3,
              },
              {
                id: '5',
                lastmod: '2021-04-11',
                changefreq: 'weekly',
                priority: 0.3,
              },
              {
                id: '6',
                lastmod: '2021-04-11',
                changefreq: 'weekly',
                priority: 0.3,
              },
              {
                id: '7',
                lastmod: '2021-04-11',
                changefreq: 'weekly',
                priority: 0.3,
              },
              {
                id: '8',
                lastmod: '2021-04-11',
                changefreq: 'weekly',
                priority: 0.3,
              },
              {
                id: '9',
                lastmod: '2021-04-11',
                changefreq: 'weekly',
                priority: 0.3,
              },
            ],
          },
        },
      },
      {
        name: 'gallery',
        path: 'gallery',
        component: () =>
          import(
            /* webpackChunkName: "parc"*/ '@/views/Client/Gallery/Gallery.vue'
          ),
        meta: {
          sitemap: {
            lastmod: '2021-04-12',
            changefreq: 'weekly',
            priority: 0.9,
          },
        },
      },
      {
        name: 'contact',
        path: 'contact',
        component: () =>
          import(
            /* webpackChunkName: "contact"*/ '@/views/Client/Contact/Contact.vue'
          ),
        meta: {
          sitemap: {
            lastmod: '2021-04-12',
            changefreq: 'weekly',
            priority: 0.8,
          },
        },
      },
      {
        name: 'apropos',
        path: 'apropos',
        component: () =>
          import(
            /* webpackChunkName: "parc"*/ '@/views/Client/Apropos/Apropos.vue'
          ),
        meta: {
          sitemap: {
            lastmod: '2021-04-16',
            changefreq: 'weekly',
            priority: 0.7,
          },
        },
      },
      {
        name: 'plan',
        path: 'plan',
        component: () =>
          import(/* webpackChunkName: "parc"*/ '@/views/Client/Plan/Plan.vue'),
        meta: {
          sitemap: {
            lastmod: '2021-04-15',
            changefreq: 'weekly',
            priority: 0.9,
          },
        },
      },
      {
        name: 'parc',
        path: 'parc',
        component: () =>
          import(/* webpackChunkName: "parc"*/ '@/views/Client/Parc/Parc.vue'),
        meta: {
          sitemap: {
            lastmod: '2021-03-15',
            changefreq: 'weekly',
            priority: 0.5,
          },
        },
      },
      {
        name: 'rules',
        path: 'rules',
        component: () =>
          import(
            /* webpackChunkName: "parc"*/ '@/views/Client/Rules/Rules.vue'
          ),
        meta: {
          sitemap: {
            lastmod: '2021-04-15',
            changefreq: 'weekly',
            priority: 0.5,
          },
        },
      },
      {
        name: 'dressing',
        path: 'dressing',
        component: () =>
          import(
            /* webpackChunkName: "parc"*/ '@/views/Client/Dressing/Dressing.vue'
          ),
        meta: {
          sitemap: {
            lastmod: '2021-05-22',
            changefreq: 'monthly',
            priority: 0.5,
          },
        },
      },
      {
        name: 'avis',
        path: 'avis',
        component: () =>
          import(/* webpackChunkName: "parc"*/ '@/views/Client/Avis/Avis.vue'),
        meta: {
          sitemap: {
            lastmod: '2021-03-22',
            changefreq: 'weekly',
            priority: 0.3,
          },
        },
      },
      {
        name: 'FAQ',
        path: 'FAQ',
        component: () =>
          import(/* webpackChunkName: "parc"*/ '@/views/Client/FAQ/FAQ.vue'),
        meta: {
          sitemap: {
            lastmod: '2021-05-08',
            changefreq: 'weekly',
            priority: 0.8,
          },
        },
      },
      {
        path: '/:pathMatch(.*)*',
        name: 'NotFound',
        component: () => import('@/views/Client/NotFound/NotFound.vue'),
      },
    ],
  },
  {
    name: 'admin',
    path: '/admin',
  },
];

export default routes;
