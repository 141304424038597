import Media from '@/utils/media.js';
const media = {
  namespaced: true,
  state() {
    return {
      cards: [
        new Media(0, '7Zq9zh7SmMs', 'video', '', ''),
        new Media(1, require('@/assets/DSC_0085_1-3.jpeg'), 'image', '', ''),
        new Media(2, require('@/assets/DSC_0597.jpeg'), 'image', '', ''),
        new Media(3, require('@/assets/DSC_9885.jpeg'), 'image', '', ''),
        new Media(4, require('@/assets/DSC_9940.jpeg'), 'image', '', ''),
        new Media(5, require('@/assets/DSC_9748-2.jpeg'), 'image', '', ''),
        new Media(6, require('@/assets/DSC_0679.jpeg'), 'image', '', ''),
      ],
      current: 0,
      type: null,
      total: 1,
    };
  },
  mutations: {
    chooseSrc(state, payload) {
      state.current = payload.id;
      state.type = payload.type;
    },
  },
  getters: {},
  actions: {},
};
export default media;
