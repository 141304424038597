import { createApp } from 'vue';
import { createRouter, createWebHistory } from 'vue-router';
import App from './App.vue';
import { routes } from './router';
import store from './store';
import axios from 'axios';
import VueAxios from 'vue-axios';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import 'jquery/dist/jquery.js';
import 'popper.js/dist/esm/popper.js';
import 'animate.css/animate.min.css';
import VTouchEvt from 'vue3-touch-events';
import { VueReCaptcha } from 'vue-recaptcha-v3';
import veProgress from 'vue-ellipse-progress';
import { VueCookieNext } from 'vue-cookie-next';
import VCalendar from 'v-calendar';

const app = createApp(App);

app.use(routes);
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  // eslint-disable-next-line no-unused-vars
  scrollBehavior(to, from, savedPosition) {
    return { left: 0, top: 0, behavior: 'smooth' };
  },
});
VueCookieNext.config({ expire: '7d' });
app
  .use(VueCookieNext)
  .use(store)
  .use(router)
  .use(VueAxios, axios)
  .use(VTouchEvt, { disableClick: true })
  .use(veProgress)
  .use(VCalendar, {
    componentPrefix: 'vc',
  })
  .use(VueReCaptcha, { siteKey: '6LefV4QaAAAAAAmeDAojeDzpU1FasKkIiqolHRIl' })
  .mount('#app');
