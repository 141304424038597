import Media from '@/utils/media.js';
const gallery = {
  namespaced: true,
  state() {
    return {
      medias: [
        new Media(0, require('@/assets/image5.jpeg'), 'image', '', ''),
        new Media(1, require('@/assets/DSC_0770.jpeg'), 'image', '', ''),
        new Media(
          2,
          require('@/assets/acqua-palace_DSC_0631.jpeg'),
          'image',
          '',
          ''
        ),
        new Media(3, require('@/assets/image2.jpeg'), 'image', '', ''),
        new Media(4, 'VCuIDrrqgQg', 'video', '', ''),
        new Media(5, require('@/assets/image3.jpeg'), 'image', '', ''),
        new Media(
          6,
          require('@/assets/acqua-palace_piscine_a_vagues.jpeg'),
          'image',
          '',
          ''
        ),
        new Media(7, require('@/assets/DSC_0030.jpeg'), 'image', '', ''),
        new Media(8, require('@/assets/DSC_0032.jpeg'), 'image', '', ''),
        new Media(9, require('@/assets/DSC_0085_1-3.jpeg'), 'image', '', ''),
        new Media(
          10,
          require('@/assets/acqua-blackhole.jpeg'),
          'image',
          '',
          ''
        ),
        new Media(11, require('@/assets/_K8A7795.jpeg'), 'image', '', ''),
        new Media(12, require('@/assets/image8.jpeg'), 'image', '', ''),
        new Media(13, require('@/assets/DSC_0755.jpeg'), 'image', '', ''),
        new Media(
          14,
          require('@/assets/acqua-palace_44.jpeg'),
          'image',
          '',
          ''
        ),
      ],
      total: 14,
    };
  },
  mutations: {},
  getters: {},
  actions: {},
};
export default gallery;
