const questions = {
  namespaced: true,
  state() {
    return {
      questions: {
        fr: [
          {
            id: 0,
            question: 'Où est situé Acqua Palace ?',
            answer:
              'L’Acqua Palace est situé à l’adresse suivante: rue des Palmiers à Port El Kantaoui, Hammam-Sousse, TUNISIE. <a href="https://www.acquapalace.com/accueil/map">Consultez la carte !</a>',
          },
          {
            id: 1,
            question: 'Quel sont les prix d’entrée au parc ?',
            answer:
              'Les prix d’entrée pour la saison 2024 sont les suivants : <ul><li>Adulte et enfant dont la taille est supérieure à 1m40 : 55 DT.</li><li>Enfant dont la taille est comprise entre 1m40 et 80cm : 45 DT.</li><li>Bébé dont la taille est inférieure à 80cm : GRATUIT.</li></ul> *L’âge du client n’a aucune importance, seule la taille est prise en considération.',
          },
          {
            id: 2,
            question:
              'Est-ce que tous les jeux sont compris dans le prix d’entrée ?',
            answer:
              'A l’Acqua Palace vous avez accès à tous les jeux selon les limites de taille et d’âge propre à chaque attraction. Pour plus d’info <a href="https://www.acquapalace.com/attractions">consultez la page attractions.</a>',
          },
          {
            id: 3,
            question: 'Qu’est ce qui est compris dans le prix d’entrée ?',
            answer:
              'Le prix d’entrée comprend l’accès à tous les jeux et piscines selon les limites de taille et d’âge propre à chaque <a href="https://www.acquapalace.com/attractions">attraction</a>, ainsi qu’aux douches et <a href="https://www.acquapalace.com/service/0">vestiaires</a>. Vous avez aussi droit à un parasol et à des chaises longues ou bien à des places en terrasse.</br>Par contre les boissons et la nourriture ne sont pas comprises dans le prix d’entrée.',
          },
          {
            id: 4,
            question: 'Quel sont les heures d’ouverture du parc ?',
            answer:
              'L’aqua palace est ouvert tous les jours de 9h30 jusqu’à 17h30 et ceci du mois de juin jusqu’au mois de septembre.',
          },
          {
            id: 5,
            question:
              'Est-il autorisé de ramener de la nourriture et des boissons ?',
            answer:
              'Il est strictement interdit d’introduire dans le parc boissons et aliments, uniquement les aliments pour bébés sont autorisés.',
          },
          {
            id: 6,
            question: 'Quel est le prix des repas ?',
            answer:
              'Pour plus d’information sur les menus proposés à l’Acqua Palace, veuillez consulter la section <a href="https://www.acquapalace.com/service/1">Bar/Resto</a> et <a href="https://www.acquapalace.com/service/2">Snacks</a>.',
          },
          {
            id: 7,
            question:
              'Le Burkini est-il autorisé et quel est le code vestimentaire ?',
            answer:
              'Le Burkini est autorisé à l’Acqua Palace. Pour plus d’information veuillez vous rendre à la section <a href="https://www.acquapalace.com/dressing">code vestimentaire</a> du site.',
          },
          {
            id: 8,
            question:
              'Est-ce que je dois payer même si je ne vais pas utiliser les piscines et les jeux?',
            answer:
              'Pour pouvoir accéder au parc vous devez obligatoirement payer un droit d’entrée même si vous n’avez pas l’intention de vous baigner.',
          },
          {
            id: 9,
            question: 'Faut-il réserver en avance avant de venir ?',
            answer:
              'Pas besoin de réserver avant votre venue au parc, les tickets sont disponibles à l’achat sur place le jour même de votre visite.</br> Seuls les groupes de plus de 30 personnes doivent réserver au moins deux jours à l’avance en appelant le service dédié, au numéro suivant <span class="desktopsOnly">(+216) 96551508</span><a class="mobilesOnly" href="tel:+21696551508">(+216) 96551508</a>',
          },
          {
            id: 10,
            question: 'Où puis-je déposer mes affaires ?',
            answer:
              'Le parc met à votre disposition des casiers sécurisés en location.',
          },
          {
            id: 11,
            question: 'Est-ce qu’il y a un service de location de serviette ?',
            answer:
              'Il n’est pas possible de louer des serviettes à l’Acqua Palace, par contre il est possible d’en acheter à la <a href="https://www.acquapalace.com/service/3">boutique</a> du parc.',
          },
          {
            id: 12,
            question: 'Les enfants non accompagnés sont-ils acceptés ?',
            answer:
              'Les enfants doivent obligatoirement être accompagnés par un adulte.',
          },
          {
            id: 13,
            question:
              'Est-ce qu’il y a des douches et des vestiaires dans le parc ?',
            answer:
              'A l’Acqua Palace vous avez libre accès aux cabines, douches et toilettes.',
          },
          {
            id: 14,
            question: 'Est-ce qu’il y a un parking pour les voitures ?',
            answer:
              'Il existe un parking gratuit d’une capacité de 90 voitures.',
          },
          {
            id: 15,
            question: 'Est-ce que l’Acqua Palace est un hôtel ?',
            answer:
              'L’acqua palace est un parc aquatique et ne dispose pas d’un service d’hébergement.',
          },
        ],
        en: [
          {
            id: 0,
            question: 'Where is the water park located ?',
            answer:
              'Acqua Palace is located in: rue des Palmiers, Port El Kantaoui, Hammam-Sousse, TUNISIE. <a href="https://www.acquapalace.com/accueil/map">Consult the map !</a>',
          },
          {
            id: 1,
            question: 'What is the entrance fee ?',
            answer:
              'The entrance prices of the 2024 season are the following : <ul><li>Adults and kids whose height is superior to 1m40 : 55 DT.</li><li>kids whose height is between 1m40 and 80cm : 45 DT.</li><li>babies whose height is inferior to 80cm : FREE.</li></ul> *The entrance fee goes according to height only.',
          },
          {
            id: 2,
            question: 'Are all the games included with the entrance ticket ?',
            answer:
              'At Acqua Palace you have access to all attractions according to their respective limits of height and age. For more information <a href="https://www.acquapalace.com/attractions">visit the attractions page.</a>',
          },
          {
            id: 3,
            question: "What's included with the entrance fee ?",
            answer:
              'The entrance fee includes the access to all games and pools according to their respective limits of height and age (see <a href="https://www.acquapalace.com/attractions">attractions</a> for more information), as well as the showers and the <a href="https://www.acquapalace.com/service/0">Locker-rooms</a>. You are also entitled to a parasol (beach umbrella) and sun loungers or to seats on the terrace.</br>Food and drinks, however, are not included.',
          },
          {
            id: 4,
            question: 'What are the opening hours of the park ?',
            answer:
              'Acqua Palace opens its doors everyday from 9:30 to 17:30, from June to September.',
          },
          {
            id: 5,
            question: 'Is it allowed to bring food and drinks ?',
            answer:
              'It is strictly forbidden to bring drinks and food in the park.</br>Only baby food is allowed.',
          },
          {
            id: 6,
            question: 'What are the prices of meals? ',
            answer:
              'For more information on the menus served at Acqua Palace, please visit the <a href="https://www.acquapalace.com/service/1">restaurant/bar</a> and <a href="https://www.acquapalace.com/service/2">Snacks</a> sections.',
          },
          {
            id: 7,
            question: 'What is the dress code ?',
            answer:
              'For more information please visit the <a href="https://www.acquapalace.com/dressing">dress code</a> section.',
          },
          {
            id: 8,
            question:
              'Do I have to pay even if I am not going to use the pools and games ?',
            answer:
              'To gain access to the park, you need to pay the entrance fee, even if you are not going to swim.',
          },
          {
            id: 9,
            question: 'Do you need to book before coming ?',
            answer:
              'There is no need to book before coming. Tickets are available for purchase at the park ticket booth.</br>Only groups of more than 30 people must book their tickets at least two days in advance by calling the appropriate service, through the following number <span class="desktopsOnly">(+216) 96551508</span><a class="mobilesOnly" href="tel:+21696551508">+21696551508</a>',
          },
          {
            id: 10,
            question: 'Where can I leave my belongings ?',
            answer: 'The park provides lockers to rent.',
          },
          {
            id: 11,
            question: 'Is there a towel rental service ?',
            answer:
              'There is no such service at Acqua Palace. However, it is possible to buy towels from the <a href="https://www.acquapalace.com/service/3">shop</a>.',
          },
          {
            id: 12,
            question: 'Are unaccompanied children allowed ?',
            answer: 'Children must be accompanied by at least one adult.',
          },
          {
            id: 13,
            question: 'Are there showers and changing rooms in the park ?',
            answer:
              'At Acqua Palace you have free access to cabins, showers and toilets. ',
          },
          {
            id: 14,
            question: 'Is there a parking lot ?',
            answer: "There's a free parking lot that fits up to 90 cars.",
          },
          {
            id: 15,
            question: 'Is Acqua Palace a Hotel ?',
            answer:
              'Acqua Palace is just a water park and does not offer an accomadation service.',
          },
        ],
        ar: [
          {
            id: 0,
            question: 'أين يقع قصر المياه ؟',
            answer:
              'يقع قصر المياه على العنوان التالي: نهج النخيل. ميناء (مرسى) القنطاوي, حمام سوسة تونس <a href="https://www.acquapalace.com/accueil/map">راجع الخريطة</a>.',
          },
          {
            id: 1,
            question: 'ماهي أسعار الدخول للمنتزه ؟',
            answer:
              'أسعار الدخول بالنسبة لموسم 2024 هي التالية :<ul><li>البالغين و الأطفال الذين يفوق طولهم 140 صم: 55 د.ت.</li><li>الأطفال الذين ينحصر طولهم بين 80 و 140 صم: 45 د.ت.</li><li>الرضع الذين يقل طولهم عن 80 صم: مجاني.</li></ul> *لا أهمية لعمر الحريف, وحده الطول هو الذي يؤخذ بعين الإعتبار.',
          },
          {
            id: 2,
            question: 'هل يشمل سعر الدخول كامل الألعاب ؟',
            answer:
              'في قصر المياه, يمكنكم الدخول إلى كامل الألعاب حسب حدود الطول و العمر الخاص بكل لعبة ترفيه.  (لمزيد المعلومات راجع صفحة <a href="https://www.acquapalace.com/attractions">ترفيه لعبة</a>)',
          },
          {
            id: 3,
            question: 'مالذي يتضمنه سعر الدخول ؟',
            answer:
              'يشمل سعر الدخول إمكانية التمتع بكل الألعاب و المسابح حسب حدود الطول و العمر الخاص بكل لعبة ترفيه (لمزيد المعلومات راجع صفحة <a href="https://www.acquapalace.com/attractions">ألعاب الترفيه</a>), و كذلك <a href="https://www.acquapalace.com/service/0">مقصورات الإستحمام و حجرات الملابس</a>. لكم أيضا الحق في شمسية و كراسي إستلقاء أو مقاعد في الفناء.</br>و على عكس ذلك, فالمشروبات و الطعام لا يشملها سعر الدخول.',
          },
          {
            id: 4,
            question: 'ماهو توقيت عمل المنتزه ؟',
            answer:
              'قصر المياه مفتوح كامل الأسبوع من الساعة ال9 و 30 صباحا إلى ال 5 و 30 بعد الظهر. و هذا بداية من شهر جوان إلى موفى سبتمبر.',
          },
          {
            id: 5,
            question: 'هل يسمح بإحضار الأطعمة و المشروبات ؟',
            answer:
              'يمنع منعا باتا إدخال المشروبات و المواد الغذائية إلى المنتزه و يسمح فقط بطعام الرضع.',
          },
          {
            id: 6,
            question: 'ما هو سعر الوجبات ؟ ',
            answer:
              'لمزيد المعلومات بخصوص قائمة الأطعمة المقترحة في قصر المياه الرجاء الرجوع إلى قسم <a href="https://www.acquapalace.com/service/1">حانة-مطعم</a> و <a href="https://www.acquapalace.com/service/2">وجبات خفيفة</a>.',
          },
          {
            id: 7,
            question:
              'هل يسمح البركيني (لباس السباحة للمحجبات) ؟ و ماهو الللباس الواجب إرتداؤه ؟',
            answer:
              'يسمح بلباس البركيني في قصر المياه. لمزيد المعلومات الرجاء الإطلاع على قسم <a href="https://www.acquapalace.com/dressing">اللباس الواجب إرتداؤه للموقع</a>.',
          },
          {
            id: 8,
            question:
              'هل يجب أن أدفع معلوم الدخول حتى إن كنت لن أستعمل المسابح و الألعاب ؟',
            answer:
              'كي تتمكن من الدخول إلى المنتزه, يجب دفع معلوم الدخول حتى و إن كنت لا تنوي السباحة.',
          },
          {
            id: 9,
            question: 'هل يجب الحجز مسبقا قبل القدوم ؟',
            answer:
              'لا حاجة للحجز قبل قدومكم إلى المنتزه, فيمكنكم إقناء التذاكر مباشرة على عين المكان يوم الزيارة نفسه.</br> يجب فقط على المجموعات التي يفوق عدد أفرادها الثلاثين الحجز قبل يومين على الأقل و ذلك عبر الإتصال بالقسم المخصص على الرقم التالي : <span class="desktopsOnly">96551508(216+)</span><a class="mobilesOnly" href="tel:+21696551508">21696551508+</a>',
          },
          {
            id: 10,
            question: 'أين يمكن إيداع أغراضي ؟',
            answer: 'يضع المنتزه على ذمتكم خزائن مؤمنة للإيجار.',
          },
          {
            id: 11,
            question: 'هل يوجد خدمة لإستئجار المناشف ؟',
            answer:
              'لا يمكن إستئجار المناشف في قصر المياه و لكن في المقابل يمكن شراؤها من <a href="https://www.acquapalace.com/service/3">متجر</a> المنتزه.',
          },
          {
            id: 12,
            question: 'هل يقبل الأطفال غير المصحوبين بالبالغين ؟',
            answer: 'مرافقة البالغين للأطفال وجوبي.',
          },
          {
            id: 13,
            question: 'هل يوجد مقصورات إستحمام و حجرات ملابس في المنتزه ؟',
            answer:
              'في قصر المياه لكم حرية الدخول لمقصورات الإستحمام و دورات المياه.',
          },
          {
            id: 14,
            question: 'هل يوجد مأوى للسيارات ؟',
            answer: 'يوجد مأوى مجاني طاقة إستيعابه 90 سيارة.',
          },
          {
            id: 15,
            question: 'هل أن قصر المياه نزل ؟',
            answer: 'قصر المياه هو منتزه مائي و لا يمتلك خدمة الإقامة.',
          },
        ],
      },
    };
  },
  mutations: {},
  getters: {
    getQuestions(state, getters, rootState, rootGetters) {
      return state.questions[rootGetters['lang/getCurrentLang']];
    },
  },
  actions: {},
};

export default questions;
