import navbarItem from '@/utils/navbarItem.js';
const navbar = {
  namespaced: true,
  state() {
    return {
      list: {
        fr: [
          new navbarItem(
            'Attractions',
            '/attractions',
            true,
            // This content will be fetched from API later stage of dev
            [
              new navbarItem('Rocket', '/attraction/0', true, undefined),
              new navbarItem('Crazy River', '/attraction/1', true, undefined),
              new navbarItem('Blackhole', '/attraction/2', true, undefined),
              new navbarItem('Space Boat', '/attraction/3', true, undefined),
              new navbarItem('Foam', '/attraction/4', true, undefined),
              new navbarItem('Kamikaze', '/attraction/5', true, undefined),
              new navbarItem('Drop', '/attraction/6', true, undefined),
              new navbarItem(
                'Piscine à vagues',
                '/attraction/7',
                true,
                undefined
              ),
              new navbarItem('Riviére Lente', '/attraction/8', true, undefined),
              new navbarItem('Acqua Baby', '/attraction/9', true, undefined),
            ]
          ),
          new navbarItem('Parc', '/parc', true, [
            new navbarItem('Plan', '/plan', true, undefined),
            new navbarItem('Galerie', '/gallery', true, undefined),
            new navbarItem('Avis', '/avis', true, undefined),
            new navbarItem('localisation', '/accueil/map', true, undefined),
            new navbarItem('Code Vestimentaire', '/dressing', true, undefined),
            new navbarItem('Reglement', '/rules', true, undefined),
            new navbarItem('A propos', '/apropos', true, undefined),
          ]),
          new navbarItem('Services', '/services', true, [
            new navbarItem('Vestiaires', '/service/0', true, undefined),
            new navbarItem('Bar/Resto', '/service/1', true, undefined),
            new navbarItem('Snacks', '/service/2', true, undefined),
            new navbarItem('Boutique', '/service/3', true, undefined),
          ]),
          new navbarItem('horaire', '/accueil/horaire', true, []),
          new navbarItem('Tarifs', '/accueil/prix', true, []),
          new navbarItem('FAQ', '/FAQ', true, []),
          new navbarItem('Contact', '/contact', true, []),
        ],
        en: [
          new navbarItem(
            'Attractions',
            '/attractions',
            true,
            // This content will be fetched from API later stage of dev
            [
              new navbarItem('Rocket', '/attraction/0', true, undefined),
              new navbarItem('Crazy River', '/attraction/1', true, undefined),
              new navbarItem('Blackhole', '/attraction/2', true, undefined),
              new navbarItem('Space Boat', '/attraction/3', true, undefined),
              new navbarItem('Foam', '/attraction/4', true, undefined),
              new navbarItem('Kamikaze', '/attraction/5', true, undefined),
              new navbarItem('Drop', '/attraction/6', true, undefined),
              new navbarItem('Wave Pool', '/attraction/7', true, undefined),
              new navbarItem('Lazy River', '/attraction/8', true, undefined),
              new navbarItem('Acqua Baby', '/attraction/9', true, undefined),
            ]
          ),
          new navbarItem('Park', '/parc', true, [
            new navbarItem('Plan', '/plan', true, undefined),
            new navbarItem('Gallery', '/gallery', true, undefined),
            new navbarItem('Reviews', '/avis', true, undefined),
            new navbarItem('localisation', '/accueil/map', true, undefined),
            new navbarItem('Dress Code', '/dressing', true, undefined),
            new navbarItem('Regulation', '/rules', true, undefined),
            new navbarItem('About Us', '/apropos', true, undefined),
          ]),
          new navbarItem('Services', '/services', true, [
            new navbarItem('Locker-rooms', '/service/0', true, undefined),
            new navbarItem('Restaurant/Bar', '/service/1', true, undefined),
            new navbarItem('Snacks', '/service/2', true, undefined),
            new navbarItem('Shop', '/service/3', true, undefined),
          ]),
          new navbarItem('Opening hours', '/accueil/horaire', true, []),
          new navbarItem('Prices', '/accueil/prix', true, []),
          new navbarItem('FAQ', '/FAQ', true, []),
          new navbarItem('Contact', '/contact', true, []),
        ],
        ar: [
          new navbarItem(
            'الألعاب',
            '/attractions',
            true,
            // This content will be fetched from API later stage of dev
            [
              new navbarItem('روكت', '/attraction/0', true, undefined),
              new navbarItem('كريزي ريفر', '/attraction/1', true, undefined),
              new navbarItem('بلاك هول', '/attraction/2', true, undefined),
              new navbarItem('سبيس بوات', '/attraction/3', true, undefined),
              new navbarItem('فوم', '/attraction/4', true, undefined),
              new navbarItem('كاميكاز', '/attraction/5', true, undefined),
              new navbarItem('دروب', '/attraction/6', true, undefined),
              new navbarItem('مسبح الأمواج', '/attraction/7', true, undefined),
              new navbarItem('نهر بطيء', '/attraction/8', true, undefined),
              new navbarItem('اكوا بيبي', '/attraction/9', true, undefined),
            ]
          ),
          new navbarItem('الحديقة مائية', '/parc', true, [
            new navbarItem('خريطة الحديقة', '/plan', true, undefined),
            new navbarItem('معرض الصور', '/gallery', true, undefined),
            new navbarItem('اراء', '/avis', true, undefined),
            new navbarItem('الموقع', '/accueil/map', true, undefined),
            new navbarItem('قانون اللباس', '/Dressing', true, undefined),
            new navbarItem('النظام الداخلي', '/rules', true, undefined),
            new navbarItem('حول', '/apropos', true, undefined),
          ]),
          new navbarItem('الخدمات', '/services', true, [
            new navbarItem('غرف خلع الملابس', '/service/0', true, undefined),
            new navbarItem('مطعم/حانة', '/service/1', true, undefined),
            new navbarItem('الوجبات الخفيفة', '/service/2', true, undefined),
            new navbarItem('محل/متجر', '/service/3', true, undefined),
          ]),
          new navbarItem('ساعات العمل', '/accueil/horaire', true, []),
          new navbarItem('الأسعار', '/accueil/prix', true, []),
          new navbarItem('الأسئلة المتكررة', '/FAQ', true, []),
          new navbarItem('اتصال', '/contact', true, []),
        ],
      },
    };
  },
  mutations: {},
  getters: {
    getList(state, getters, rootState, rootGetters) {
      return state.list[rootGetters['lang/getCurrentLang']];
    },
    filterList: () => (list) => {
      return list.filter((el) => {
        return el.isActive === true;
      });
    },
  },
  actions: {},
};
export default navbar;
