class Media {
    constructor(id,url,type,title,description){
        this.id = id
        if(type === "video"){
            this.url = "https://www.youtube.com/embed/"+ url
        }else{
            this.url = url
        }
        this.type = type
        this.title = title
        this.description = description
    }
}
export default Media