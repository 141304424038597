<template>
  <router-view class="default-theme"></router-view>
</template>

<script>
export default {
  name: "app",
  data() {
    return {};
  },
  methods: {},
};
</script>

<style src="./App.css"></style>
