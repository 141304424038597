const services = {
  namespaced: true,
  state() {
    return {
      services: {
        fr: [
          {
            id: 0,
            name: 'Vestiaires/Casiers',
            imgUrl: require('@/assets/vestiaires-5.jpg'),
            description:
              'Afin d’assurer un service à la perfection, nous avons mis à votre disposition des vestiaires avec des cabines pour se changer, douches, WC et location de casiers pour mettre vos objets de valeurs et vêtements si vous ne voulez pas les sortir avec vous sur la piscine, vous pouvez enfiler votre maillot avant de plonger dans la piscine ou une douche bien fraîche avant de rentrer chez soi.',
            alt_text: '',
          },
          {
            id: 1,
            name: 'Bar/Resto',
            imgUrl: require('@/assets/resto bar.jpeg'),
            description:
              'L\'Acqua Palace  vous propose plusieurs formules de consommation, selon les saisons, le restaurant " le Corail" offre un service self service tous les jours durant la saison de 11h30 à 16h. Quant au bar "Bistrot" il est ouvert toute l\'année de 16h à minuit et ce même lorsque le parc est fermé',
            alt_text: '',
            menu_text: 'Menu',
            menus: [
              {
                id: 0,
                name: '🥗 Salades 🥗',
                content: [
                  {
                    id: 0,
                    name: 'Salade Tomate',
                    price: '7.5',
                  },
                  {
                    id: 1,
                    name: 'Salade Tunisienne',
                    price: '8.5',
                  },
                ],
              },
              {
                id: 1,
                name: '🍟 Les entrées chaudes 🍟',
                content: [
                  {
                    id: 0,
                    name: 'Frites',
                    price: '9',
                  },
                  {
                    id: 1,
                    name: 'Omelette Nature',
                    price: '10',
                  },
                  {
                    id: 2,
                    name: 'Omelette Thon',
                    price: '15',
                  },
                  {
                    id: 3,
                    name: 'Omelette Fromage',
                    price: '16',
                  },
                  {
                    id: 4,
                    name: 'Omelette Thon Fromage',
                    price: '18.5',
                  },
                  {
                    id: 5,
                    name: 'Ojja Mergez',
                    price: '21',
                  },
                ],
              },
              {
                id: 2,
                name: '🐟 Poissons 🐟',
                content: [
                  {
                    id: 0,
                    name: 'Poisson Grillé',
                    price: '32',
                  },
                ],
              },
              {
                id: 4,
                name: '🍗 Volailles 🍗',
                content: [
                  {
                    id: 0,
                    name: 'Cuisse de Poulet',
                    price: '19',
                  },
                  {
                    id: 1,
                    name: 'Suprême De Poulet',
                    price: '20',
                  },
                  {
                    id: 2,
                    name: 'Chicken Nuggets',
                    price: '19',
                  },
                  {
                    id: 3,
                    name: 'Escalope de dinde grillée',
                    price: '19',
                  },
                  {
                    id: 4,
                    name: 'Escalope de dinde panée',
                    price: '20',
                  },
                  {
                    id: 5,
                    name: 'Cordon Bleu',
                    price: '20',
                  },
                  {
                    id: 6,
                    name: 'Brochette de dinde',
                    price: '20',
                  },
                ],
              },
              {
                id: 5,
                name: '🥩 Viandes 🥩',
                content: [
                  {
                    id: 0,
                    name: 'Complet Merguez',
                    price: '24',
                  },
                  {
                    id: 1,
                    name: 'Grillade Mixte',
                    price: '45',
                  },
                  {
                    id: 2,
                    name: 'Spaghettis Bolognaise',
                    price: '30',
                  },
                ],
              },
              {
                id: 6,
                name: '🍔 Hamburgers 🍔',
                content: [
                  {
                    id: 0,
                    name: 'Hamburger Boeuf',
                    price: '17',
                  },
                  {
                    id: 1,
                    name: 'Hamburger Dinde',
                    price: '16',
                  },
                ],
              },
              /*{
                id: 7,
                name: '🥪 Sandwichs 🥪',
                content: [
                  {
                    id: 0,
                    name: 'Sandwich salami',
                    price: '6',
                  },
                  {
                    id: 1,
                    name: 'Sandwich Thon',
                    price: '9',
                  },
                  {
                    id: 2,
                    name: 'Sandwich Escalope',
                    price: '8',
                  },
                ],
              },*/
              {
                id: 8,
                name: '🍕 Pizzas 🍕',
                content: [
                  {
                    id: 0,
                    name: 'Pizza Margherita',
                    price: '15',
                  },
                  {
                    id: 1,
                    name: 'Pizza Jambon salami',
                    price: '18',
                  },
                  {
                    id: 2,
                    name: 'Pizza Pepperoni',
                    price: '18',
                  },
                  {
                    id: 3,
                    name: 'Pizza Champignon',
                    price: '18',
                  },
                  {
                    id: 4,
                    name: 'Pizza Végétarienne',
                    price: '18',
                  },
                  {
                    id: 5,
                    name: 'Pizza Escalope',
                    price: '20',
                  },
                  {
                    id: 6,
                    name: 'Pizza Thon',
                    price: '24',
                  },
                  {
                    id: 7,
                    name: 'Pizza Chef',
                    price: '25',
                  },
                  {
                    id: 8,
                    name: 'Pizza Bolognaise',
                    price: '30',
                  },
                ],
              },
              {
                id: 9,
                name: '🍎 Dessert 🍎',
                content: [
                  {
                    id: 0,
                    name: 'Fruits de saison',
                    price: '8',
                  },
                ],
              },
              {
                id: 10,
                name: '🥤 Boissons Froides 🥤',
                content: [
                  {
                    id: 0,
                    name: 'Sodas Boite',
                    price: '4',
                  },
                  {
                    id: 1,
                    name: 'Safia 1,5L',
                    price: '3',
                  },
                  {
                    id: 2,
                    name: 'Safia 0,5L',
                    price: '2',
                  },
                  {
                    id: 3,
                    name: 'Shark',
                    price: '10',
                  },
                  {
                    id: 4,
                    name: 'Celtia Boite',
                    price: '7.5',
                  },
                  {
                    id: 5,
                    name: "Beck's Boite",
                    price: '7.5',
                  },
                  {
                    id: 6,
                    name: 'Heineken Boite',
                    price: '7.5',
                  },
                ],
              },
            ],
          },
          {
            id: 2,
            name: 'Snacks',
            imgUrl: require('@/assets/acquapalace-kantaoui-tunisia_03.jpeg'),
            description:
              "Profitez de nos grands espaces de restaurations et nos différents points de ventes, dont 3 snacks \"le Pirate, L'Atlantis et la Sirene\" répartis dans tout le parc, selon votre choix et selon votre bourse, si vous n'aimez pas le service à table, vous avez aussi l'option service rapide à emporter. Nous avons mis à votre disposition tous les services dont vous avez besoin, passez commande d'une boisson rafraîchissante, d'un repas à l'ombre d'un parasol ou de la terrasse, d'un petit plaisir à grignoter, un éventail de choix à tout prix pour que chacun trouve ce qu'il cherche, de quoi profiter de cet agréable moment en rassemblant les forces nécessaires pour poursuivre votre belle journée au parc.",
            alt_text: '',
            menu_text: 'Menu',
            menus: [
              {
                id: 0,
                name: '☕ Boissons Chaudes ☕',
                content: [
                  {
                    id: 0,
                    name: 'Express',
                    price: '4',
                  },
                  {
                    id: 1,
                    name: 'Capucin',
                    price: '4.5',
                  },
                  {
                    id: 2,
                    name: 'Crème',
                    price: '5',
                  },
                  {
                    id: 3,
                    name: 'Chocolat au lait',
                    price: '3.5',
                  },
                ],
              },
              {
                id: 1,
                name: '🥤 Boissons Froides 🥤',
                content: [
                  {
                    id: 0,
                    name: 'Sodas Boite',
                    price: '4',
                  },
                  {
                    id: 1,
                    name: 'Safia 1,5L',
                    price: '3',
                  },
                  {
                    id: 2,
                    name: 'Safia 0,5L',
                    price: '2',
                  },
                  {
                    id: 3,
                    name: 'Shark',
                    price: '10',
                  },
                  {
                    id: 4,
                    name: 'Celtia Boite',
                    price: '7.5',
                  },
                  {
                    id: 5,
                    name: "Beck's Boite",
                    price: '7.5',
                  },
                  {
                    id: 6,
                    name: 'Heineken Boite',
                    price: '7.5',
                  },
                ],
              },
              {
                id: 2,
                name: '🥪 Sandwichs 🥪',
                content: [
                  {
                    id: 0,
                    name: 'Sandwich salami',
                    price: '7',
                  },
                  {
                    id: 1,
                    name: 'Sandwich Thon',
                    price: '10',
                  },
                  {
                    id: 2,
                    name: 'Sandwich Escalope',
                    price: '9',
                  },
                ],
              },
              {
                id: 3,
                name: '🌯 Paninis 🌯',
                content: [
                  {
                    id: 0,
                    name: 'Panini fromage',
                    price: '7',
                  },
                  {
                    id: 1,
                    name: 'Panini salami fromage',
                    price: '7.5',
                  },
                  {
                    id: 2,
                    name: 'Panini jambon fromage',
                    price: '8',
                  },
                  {
                    id: 3,
                    name: 'Panini Thon',
                    price: '10',
                  },
                  {
                    id: 4,
                    name: 'Panini Escalope',
                    price: '9',
                  },
                  {
                    id: 5,
                    name: 'Panini Spécial',
                    price: '10.5',
                  },
                ],
              },
              {
                id: 4,
                name: '🥞 Crêpes 🥞',
                content: [
                  /*{
                    id: 0,
                    name: 'Crêpe au sucre',
                    price: '3.5',
                  },*/
                  {
                    id: 1,
                    name: 'Crêpe au chocolat',
                    price: '6',
                  },
                  {
                    id: 2,
                    name: 'Crêpe au Nutella',
                    price: '9',
                  },
                  {
                    id: 3,
                    name: 'Crêpe Fromage',
                    price: '6',
                  },
                  {
                    id: 4,
                    name: 'Crêpe salami fromage',
                    price: '7',
                  },
                  {
                    id: 5,
                    name: 'Crêpe jambon fromage',
                    price: '8',
                  },
                  {
                    id: 6,
                    name: 'Crêpe escalope fromage',
                    price: '8.5',
                  },
                  {
                    id: 7,
                    name: 'Crêpe thon fromage',
                    price: '10',
                  },
                  {
                    id: 8,
                    name: 'Crêpe Spécial',
                    price: '11',
                  },
                ],
              },
              {
                id: 5,
                name: '🍦 Glaces 🍦',
                content: [
                  {
                    id: 0,
                    name: 'Alaska',
                    price: '1.8',
                  },
                  {
                    id: 1,
                    name: 'Baloo',
                    price: '1.8',
                  },
                  /*{
                    id: 2,
                    name: 'Clown',
                    price: '2,2',
                  },
                  {
                    id: 3,
                    name: 'Charlot',
                    price: '2,2',
                  },*/
                  {
                    id: 4,
                    name: 'Magtop',
                    price: '2,8',
                  },
                  {
                    id: 5,
                    name: 'Twister',
                    price: '2,8',
                  },
                  /*{
                    id: 6,
                    name: 'Tom et Jerry',
                    price: '1,5',
                  },*/
                  {
                    id: 7,
                    name: 'Picsou',
                    price: '2.5',
                  },
                  {
                    id: 8,
                    name: 'Fiesta',
                    price: '2,5',
                  },
                  {
                    id: 9,
                    name: 'Amigo',
                    price: '3',
                  },
                  {
                    id: 10,
                    name: 'Amigo Max',
                    price: '4',
                  },
                  {
                    id: 11,
                    name: 'Gold',
                    price: '4',
                  },
                  /*{
                    id: 12,
                    name: 'XL',
                    price: '3,7',
                  },*/
                ],
              },
              {
                id: 5,
                name: '🍫 Snacks 🍫',
                content: [
                  {
                    id: 0,
                    name: 'Chips 16G',
                    price: '2.5',
                  },
                  /*{
                    id: 1,
                    name: 'Chips 75G',
                    price: '4',
                  },*/
                  {
                    id: 2,
                    name: 'Gaufrette',
                    price: '2.5',
                  },
                  {
                    id: 3,
                    name: 'Sablito',
                    price: '3.5',
                  },
                  {
                    id: 4,
                    name: 'Yaourt à boire',
                    price: '2',
                  },
                  {
                    id: 5,
                    name: 'Vitalo',
                    price: '2',
                  },
                  {
                    id: 6,
                    name: 'Jus 25Cl',
                    price: '3.5',
                  },
                  /*{
                    id: 4,
                    name: 'Mars',
                    price: '3.5',
                  },
                  {
                    id: 5,
                    name: 'Snickers',
                    price: '3.5',
                  },*/
                ],
              },
              {
                id: 6,
                name: '🚬 Autres Produits 🚬',
                content: [
                  {
                    id: 0,
                    name: 'Briquet',
                    price: '2.5',
                  },
                ],
              },
            ],
          },
          {
            id: 3,
            name: 'Boutique',
            imgUrl: require('@/assets/d8575c18d5c8396bb3826cb1ad9922bc.jpeg'),
            description:
              'Nous avons mis à votre disposition une boutique pour vos achats improvisés, si vous avez oublié votre maillot, une bouée, une crème solaire, des cigarettes... Vous pouvez trouver tout ce dont vous avez besoin.',
            alt_text: '',
            menu_text: 'Produits',
            menus: [
              {
                id: 0,
                name: 'Bouées',
                content: [
                  {
                    id: 0,
                    name: 'Gilet Gonflable Enfant',
                    price: '15',
                  },
                  {
                    id: 1,
                    name: 'Bouée Enfant Anneau 50 Cm',
                    price: '5',
                  },
                  {
                    id: 2,
                    name: 'Bouée Enfant Anneau 60 Cm',
                    price: '6',
                  },
                  {
                    id: 3,
                    name: 'Bouée Anneau 1,22m',
                    price: '30',
                  },
                  {
                    id: 4,
                    name: 'Pair de Brassard',
                    price: '6',
                  },
                ],
              },
              {
                id: 1,
                name: '🩱 Maillots de bain 🩱',
                content: [
                  {
                    id: 0,
                    name: 'Cyclisme',
                    price: '15',
                  },
                  {
                    id: 1,
                    name: 'Bikini',
                    price: '15',
                  },
                  {
                    id: 2,
                    name: 'Burkini',
                    price: '65',
                  },
                  {
                    id: 3,
                    name: 'Short Homme',
                    price: '15-20',
                  },
                  {
                    id: 4,
                    name: 'Short Femme',
                    price: '15',
                  },
                  {
                    id: 5,
                    name: 'Short Enfant',
                    price: '18',
                  },
                  {
                    id: 6,
                    name: 'Maillot fille',
                    price: '20-30-50',
                  },
                ],
              },
              {
                id: 2,
                name: '👗 Vétements de plage 👗',
                content: [
                  {
                    id: 0,
                    name: 'Cache Jupe',
                    price: '15',
                  },
                  {
                    id: 1,
                    name: 'Robe de Plage',
                    price: '18-30',
                  },
                  {
                    id: 2,
                    name: 'Serviette de Bain GM',
                    price: '13',
                  },
                  {
                    id: 3,
                    name: 'Jebba de Plage Femme',
                    price: '35',
                  },
                  {
                    id: 4,
                    name: 'T-shirt',
                    price: '10-12-15',
                  },
                  {
                    id: 5,
                    name: 'Chapeau Femme',
                    price: '15',
                  },
                  {
                    id: 6,
                    name: 'Chapeau Garçon',
                    price: '12',
                  },
                  {
                    id: 7,
                    name: 'Chapeau Fille',
                    price: '12',
                  },
                ],
              },
              {
                id: 3,
                name: '🍫 Snacks 🍫',
                content: [
                  {
                    id: 0,
                    name: 'Chips 16G',
                    price: '1.5',
                  },
                  {
                    id: 1,
                    name: 'Chips 75G',
                    price: '3.5',
                  },
                  {
                    id: 2,
                    name: 'Gaufrette',
                    price: '1.2',
                  },
                  {
                    id: 3,
                    name: 'Sablito',
                    price: '2.5',
                  },
                  {
                    id: 4,
                    name: 'Mars',
                    price: '3.5',
                  },
                  {
                    id: 5,
                    name: 'Snickers',
                    price: '3.5',
                  },
                ],
              },
              {
                id: 4,
                name: '🧴 Crèmes Solaires 🧴',
                content: [
                  {
                    id: 0,
                    name: 'Crème Solaire Nivea 200ml',
                    price: '35',
                  },
                  {
                    id: 1,
                    name: 'Crème Solaire Nivea sun 50+ 200ml',
                    price: '60',
                  },
                  {
                    id: 2,
                    name: 'Crème Solaire Nivea sun 30+ 200ml',
                    price: '50',
                  },
                ],
              },
            ],
          },
        ],
        en: [
          {
            id: 0,
            name: 'Locker-rooms',
            imgUrl: require('@/assets/vestiaires-5.jpg'),
            description:
              'To ensure a perfect service, we have put at your disposal locker-rooms with cabins to change, showers, WC, and lockers to store your valuables and clothes (in case you prefer not to take them with you to the pool). They allow you to quickly put on your swimming suit before you jump in the pool or take a refreshing shower before going home.',
            alt_text: '',
          },
          {
            id: 1,
            name: 'Restaurant/Bar',
            imgUrl: require('@/assets/resto bar.jpeg'),
            description:
              'Acqua Palace offers numerous consumption formulas, which go according to season. The self-service restaurant: “Le Corail” is open every day through the whole season from 11:30 a.m to 4 p.m. The bar “Bistrot” opens its doors the entire year- from 4 p.m to midnight- even after the park’s closing hours.',
            alt_text: '',
            menu_text: 'Menu',
            menus: [
              {
                id: 0,
                name: '🥗 Salads 🥗',
                content: [
                  {
                    id: 0,
                    name: 'Tomato Salad',
                    price: '7.5',
                  },
                  {
                    id: 1,
                    name: 'Tunisian Salad',
                    price: '8.5',
                  },
                ],
              },
              {
                id: 1,
                name: '🍟 Hot Starters 🍟',
                content: [
                  {
                    id: 0,
                    name: 'Fries',
                    price: '9',
                  },
                  {
                    id: 1,
                    name: 'Nature Omelette',
                    price: '10',
                  },
                  {
                    id: 2,
                    name: 'Tuna Omelette',
                    price: '15',
                  },
                  {
                    id: 3,
                    name: 'Cheese Omelette',
                    price: '16',
                  },
                  {
                    id: 4,
                    name: 'Tuna & Cheese Omelette',
                    price: '18.5',
                  },
                  {
                    id: 5,
                    name: 'Ojja Mergez',
                    price: '21',
                  },
                ],
              },
              {
                id: 2,
                name: '🐟 Fishes 🐟',
                content: [
                  {
                    id: 0,
                    name: 'Grilled Fish',
                    price: '32',
                  },
                ],
              },
              {
                id: 4,
                name: '🍗 Poultry  🍗',
                content: [
                  {
                    id: 0,
                    name: 'Chicken leg',
                    price: '19',
                  },
                  {
                    id: 1,
                    name: 'Chicken Supreme',
                    price: '20',
                  },
                  {
                    id: 2,
                    name: 'Chicken Nuggets',
                    price: '19',
                  },
                  {
                    id: 3,
                    name: 'Grilled Turkey Escalope',
                    price: '19',
                  },
                  {
                    id: 4,
                    name: 'Breaded Turkey Escalope',
                    price: '20',
                  },
                  {
                    id: 5,
                    name: 'Cordon Bleu',
                    price: '20',
                  },
                  {
                    id: 6,
                    name: 'Turkey skewer',
                    price: '20',
                  },
                ],
              },
              {
                id: 5,
                name: '🥩 Meats 🥩',
                content: [
                  {
                    id: 0,
                    name: 'Complete Merguez',
                    price: '24',
                  },
                  {
                    id: 1,
                    name: 'Mixed grill',
                    price: '45',
                  },
                  {
                    id: 2,
                    name: 'Spaghetti Bolognese',
                    price: '30',
                  },
                ],
              },
              {
                id: 6,
                name: '🍔 Hamburgers 🍔',
                content: [
                  {
                    id: 0,
                    name: 'Beef Hamburger',
                    price: '17',
                  },
                  {
                    id: 1,
                    name: 'Turkey Hamburger',
                    price: '16',
                  },
                ],
              },
              /*{
                id: 7,
                name: '🥪 Sandwiches 🥪',
                content: [
                  {
                    id: 0,
                    name: 'Salami Sandwich',
                    price: '6',
                  },
                  {
                    id: 1,
                    name: 'Tuna Sandwich',
                    price: '9',
                  },
                  {
                    id: 2,
                    name: 'Escalope Sandwich',
                    price: '8',
                  },
                ],
              },*/
              {
                id: 8,
                name: '🍕 Pizzas 🍕',
                content: [
                  {
                    id: 0,
                    name: 'Pizza Margherita',
                    price: '15',
                  },
                  {
                    id: 1,
                    name: 'Pizza Ham & Salami',
                    price: '18',
                  },
                  {
                    id: 2,
                    name: 'Pizza Pepperoni',
                    price: '18',
                  },
                  {
                    id: 3,
                    name: 'Pizza mushroom',
                    price: '18',
                  },
                  {
                    id: 4,
                    name: 'Pizza vegetarian',
                    price: '18',
                  },
                  {
                    id: 5,
                    name: 'Pizza Escalope',
                    price: '20',
                  },
                  {
                    id: 6,
                    name: 'Pizza Tuna',
                    price: '24',
                  },
                  {
                    id: 7,
                    name: 'Pizza Chef',
                    price: '25',
                  },
                  {
                    id: 8,
                    name: 'Pizza Bolognese',
                    price: '30',
                  },
                ],
              },
              {
                id: 9,
                name: '🍎 Dessert 🍎',
                content: [
                  {
                    id: 0,
                    name: 'Seasonal fruits',
                    price: '8',
                  },
                ],
              },
              {
                id: 10,
                name: '🥤 Cold drinks 🥤',
                content: [
                  {
                    id: 0,
                    name: 'Soda can',
                    price: '4',
                  },
                  {
                    id: 1,
                    name: 'Safia 1,5L',
                    price: '3',
                  },
                  {
                    id: 2,
                    name: 'Safia 0,5L',
                    price: '2',
                  },
                  {
                    id: 3,
                    name: 'Shark',
                    price: '10',
                  },
                  {
                    id: 4,
                    name: 'Celtia Can',
                    price: '7.5',
                  },
                  {
                    id: 5,
                    name: "Beck's Can",
                    price: '7.5',
                  },
                  {
                    id: 6,
                    name: 'Heineken Can',
                    price: '7.5',
                  },
                ],
              },
            ],
          },
          {
            id: 2,
            name: 'Snacks',
            imgUrl: require('@/assets/acquapalace-kantaoui-tunisia_03.jpeg'),
            description:
              'Enjoy our large restaurant areas and our different snack-shops: “Le Pirate”, “L’Atlantis” and “La Sirene” scattered throughout the park.  The shops go according to your choice and budget. If you are not a fan of being served on a table, you also have the choice of take-out fast-food. We have put at your disposal all of the services that you will need. Order a refreshing drink, a meal under the shade of a beach umbrella or in the terrace, or take pleasure in a quick snack: a wide range of selections for every price, so everyone can find what they look for. Enjoy this pleasant moment by gathering the necessary energy to continue your beautiful day at the park.',
            alt_text: '',
            menu_text: 'Menu',
            menus: [
              {
                id: 0,
                name: '☕ Hot drinks ☕',
                content: [
                  {
                    id: 0,
                    name: 'Express',
                    price: '4',
                  },
                  {
                    id: 1,
                    name: 'Capucin',
                    price: '4.5',
                  },
                  {
                    id: 2,
                    name: 'cream coffee',
                    price: '5',
                  },
                  {
                    id: 3,
                    name: 'Milk chocolate',
                    price: '3.5',
                  },
                ],
              },
              {
                id: 1,
                name: '🥤 Cold Drinks 🥤',
                content: [
                  {
                    id: 0,
                    name: 'Soda can',
                    price: '4',
                  },
                  {
                    id: 1,
                    name: 'Safia 1,5L',
                    price: '3',
                  },
                  {
                    id: 2,
                    name: 'Safia 0,5L',
                    price: '2',
                  },
                  {
                    id: 3,
                    name: 'Shark',
                    price: '10',
                  },
                  {
                    id: 4,
                    name: 'Celtia Can',
                    price: '7.5',
                  },
                  {
                    id: 5,
                    name: "Beck's Can",
                    price: '7.5',
                  },
                  {
                    id: 6,
                    name: 'Heineken Can',
                    price: '7.5',
                  },
                ],
              },
              {
                id: 2,
                name: '🥪 Sandwiches 🥪',
                content: [
                  {
                    id: 0,
                    name: 'Salami Sandwich',
                    price: '7',
                  },
                  {
                    id: 1,
                    name: 'Tuna Sandwich',
                    price: '10',
                  },
                  {
                    id: 2,
                    name: 'Escalope Sandwich',
                    price: '9',
                  },
                ],
              },
              {
                id: 3,
                name: '🌯 Paninis 🌯',
                content: [
                  {
                    id: 0,
                    name: 'Cheese Panini',
                    price: '7',
                  },
                  {
                    id: 1,
                    name: 'Salami & Cheese Panini',
                    price: '7.5',
                  },
                  {
                    id: 2,
                    name: 'Ham & Cheese Panini',
                    price: '8',
                  },
                  {
                    id: 3,
                    name: 'Tuna Panini',
                    price: '10',
                  },
                  {
                    id: 4,
                    name: 'Escalope Panini',
                    price: '9',
                  },
                  {
                    id: 5,
                    name: 'Special Panini',
                    price: '10.5',
                  },
                ],
              },
              {
                id: 4,
                name: '🥞 Crepes 🥞',
                content: [
                  /*{
                    id: 0,
                    name: 'Sugar Crepe',
                    price: '3.5',
                  },*/
                  {
                    id: 1,
                    name: 'chocolate Crepe',
                    price: '6',
                  },
                  {
                    id: 2,
                    name: 'Nutella Crepe',
                    price: '9',
                  },
                  {
                    id: 3,
                    name: 'Cheese Crepe',
                    price: '6',
                  },
                  {
                    id: 4,
                    name: 'Salami & Cheese Crepe',
                    price: '7',
                  },
                  {
                    id: 5,
                    name: 'Ham & Cheese Crepe',
                    price: '8',
                  },
                  {
                    id: 6,
                    name: 'Escalope & Cheese Crepe',
                    price: '8.5',
                  },
                  {
                    id: 7,
                    name: 'Tuna & Cheese Crepe',
                    price: '10',
                  },
                  {
                    id: 8,
                    name: 'Special Crepe',
                    price: '11',
                  },
                ],
              },
              {
                id: 5,
                name: '🍦 ice cream  🍦',
                content: [
                  {
                    id: 0,
                    name: 'Alaska',
                    price: '1.8',
                  },
                  {
                    id: 1,
                    name: 'Baloo',
                    price: '1.8',
                  },
                  /*{
                    id: 2,
                    name: 'Clown',
                    price: '2,2',
                  },
                  {
                    id: 3,
                    name: 'Charlot',
                    price: '2,2',
                  },*/
                  {
                    id: 4,
                    name: 'Magtop',
                    price: '2,8',
                  },
                  {
                    id: 5,
                    name: 'Twister',
                    price: '2,8',
                  },
                  /*{
                    id: 6,
                    name: 'Tom et Jerry',
                    price: '1,5',
                  },*/
                  {
                    id: 7,
                    name: 'Picsou',
                    price: '2.5',
                  },
                  {
                    id: 8,
                    name: 'Fiesta',
                    price: '2,5',
                  },
                  {
                    id: 9,
                    name: 'Amigo',
                    price: '3',
                  },
                  {
                    id: 10,
                    name: 'Amigo Max',
                    price: '4',
                  },
                  {
                    id: 11,
                    name: 'Gold',
                    price: '4',
                  },
                  /*{
                    id: 12,
                    name: 'XL',
                    price: '3,7',
                  },*/
                ],
              },
              {
                id: 5,
                name: '🍫 Snacks 🍫',
                content: [
                  {
                    id: 0,
                    name: 'Chips 16G',
                    price: '2.5',
                  },
                  /*{
                    id: 1,
                    name: 'Chips 75G',
                    price: '4',
                  },*/
                  {
                    id: 2,
                    name: 'Gaufrette',
                    price: '2.5',
                  },
                  {
                    id: 3,
                    name: 'Sablito',
                    price: '3.5',
                  },
                  {
                    id: 4,
                    name: 'Drinking Yogurt',
                    price: '2',
                  },
                  {
                    id: 5,
                    name: 'Vitalo',
                    price: '2',
                  },
                  {
                    id: 6,
                    name: '25Cl Juice',
                    price: '3.5',
                  },
                  /*{
                    id: 4,
                    name: 'Mars',
                    price: '3.5',
                  },
                  {
                    id: 5,
                    name: 'Snickers',
                    price: '3.5',
                  },*/
                ],
              },
              {
                id: 6,
                name: '🚬 other products 🚬',
                content: [
                  {
                    id: 0,
                    name: 'Lighter',
                    price: '2.5',
                  },
                ],
              },
            ],
          },
          {
            id: 3,
            name: 'Shop',
            imgUrl: require('@/assets/d8575c18d5c8396bb3826cb1ad9922bc.jpeg'),
            description:
              'We have put at your disposal a shop for your unexpected purchases. If you have forgotten your swimming suit, floater, sun-screen, cigarettes … You can find all that you need here.',
            alt_text: '',
            menu_text: 'Products',
            menus: [
              {
                id: 0,
                name: 'Floats',
                content: [
                  {
                    id: 0,
                    name: 'Child Inflatable Vest',
                    price: '15',
                  },
                  {
                    id: 1,
                    name: '50 Cm Child Ring float',
                    price: '5',
                  },
                  {
                    id: 2,
                    name: '60 Cm Child Ring float',
                    price: '6',
                  },
                  {
                    id: 3,
                    name: '1.22m Ring float',
                    price: '30',
                  },
                  {
                    id: 4,
                    name: 'Pair of Armbands',
                    price: '6',
                  },
                ],
              },
              {
                id: 1,
                name: '🩱 Swimsuits 🩱',
                content: [
                  {
                    id: 0,
                    name: 'Cyclism',
                    price: '15',
                  },
                  {
                    id: 1,
                    name: 'Bikini',
                    price: '15',
                  },
                  {
                    id: 2,
                    name: 'Burkini',
                    price: '65',
                  },
                  {
                    id: 3,
                    name: 'man short',
                    price: '15-20',
                  },
                  {
                    id: 4,
                    name: 'woman short',
                    price: '15',
                  },
                  {
                    id: 5,
                    name: 'Kid short',
                    price: '18',
                  },
                  {
                    id: 6,
                    name: 'Girl swimsuit',
                    price: '20-30-50',
                  },
                ],
              },
              {
                id: 2,
                name: '👗 Beach clothes 👗',
                content: [
                  {
                    id: 0,
                    name: 'swimsuit cover',
                    price: '15',
                  },
                  {
                    id: 1,
                    name: 'Beach dress',
                    price: '18-30',
                  },
                  {
                    id: 2,
                    name: 'GM towel',
                    price: '13',
                  },
                  {
                    id: 3,
                    name: 'Woman beach Jebba',
                    price: '35',
                  },
                  {
                    id: 4,
                    name: 'T-shirt',
                    price: '10-12-15',
                  },
                  {
                    id: 5,
                    name: 'Women hat',
                    price: '15',
                  },
                  {
                    id: 6,
                    name: 'Boy hat',
                    price: '12',
                  },
                  {
                    id: 7,
                    name: 'Girl hat',
                    price: '12',
                  },
                ],
              },
              {
                id: 3,
                name: '🍫 Snacks 🍫',
                content: [
                  {
                    id: 0,
                    name: 'Chips 16G',
                    price: '1.5',
                  },
                  {
                    id: 1,
                    name: 'Chips 75G',
                    price: '3.5',
                  },
                  {
                    id: 2,
                    name: 'Gaufrette',
                    price: '1.2',
                  },
                  {
                    id: 3,
                    name: 'Sablito',
                    price: '2.5',
                  },
                  {
                    id: 4,
                    name: 'Mars',
                    price: '3.5',
                  },
                  {
                    id: 5,
                    name: 'Snickers',
                    price: '3.5',
                  },
                ],
              },
              {
                id: 4,
                name: '🧴 Solar creams 🧴',
                content: [
                  {
                    id: 0,
                    name: 'Solar cream Nivea 200ml',
                    price: '35',
                  },
                  {
                    id: 1,
                    name: 'Solar cream Nivea sun 50+ 200ml',
                    price: '60',
                  },
                  {
                    id: 2,
                    name: 'Solar cream Nivea sun 30+ 200ml',
                    price: '50',
                  },
                ],
              },
            ],
          },
        ],
        ar: [
          {
            id: 0,
            name: 'غرف خلع الملابس',
            imgUrl: require('@/assets/vestiaires-5.jpg'),
            description:
              'لضمان خدمة مثالية، وضعنا تحت تصرفك غرف خلع الملابس مع كبائن للتغيير، ودش، ومرحاض، وخزائن لتخزين الأشياء الثمينة والملابس الخاصة بك (في حال كنت تفضل عدم اصطحابها معك إلى المسبح). إنها تسمح لك بارتداء بدلة السباحة الخاصة بك بسرعة قبل القفز في المسبح أو الاستحمام قبل الذهاب إلى المنزل. ',
            alt_text: '',
          },
          {
            id: 1,
            name: 'مطعم/حانة',
            imgUrl: require('@/assets/resto bar.jpeg'),
            description:
              '.يقترح عليكم قصر المياه تشكيلة إستهلاك متنوعة حسب المواسم. مطعم "الكوراي" يعرض الخدمة الذاتية خدمة ذاتية يوميا طوال الموسم من الساعة 11:30 إلي 16:00. أما بالنسبة للحانة "البيسترو" فهي مفتوحة على مدار السنة من الساعة 16:00 إلى 00.00 حتى عندما يغلق المنتزه أبوابه',
            alt_text: '',
            menu_text: 'قائمة',
            menus: [
              {
                id: 0,
                name: '🥗 سلطة 🥗',
                content: [
                  {
                    id: 0,
                    name: 'سلطة طماطم',
                    price: '7.5',
                  },
                  {
                    id: 1,
                    name: 'سلطة تونسية',
                    price: '8.5',
                  },
                ],
              },
              {
                id: 1,
                name: '🍟 مقبلات ساخنة 🍟',
                content: [
                  {
                    id: 0,
                    name: 'بطاطس مقلية',
                    price: '9',
                  },
                  {
                    id: 1,
                    name: 'أومليت الطبيعة',
                    price: '10',
                  },
                  {
                    id: 2,
                    name: 'أومليت تونة',
                    price: '15',
                  },
                  {
                    id: 3,
                    name: 'أومليت بالجبن',
                    price: '16',
                  },
                  {
                    id: 4,
                    name: 'أومليت التونة والجبن',
                    price: '18.5',
                  },
                  {
                    id: 5,
                    name: 'أوجا ميرغيز',
                    price: '21',
                  },
                ],
              },
              {
                id: 2,
                name: '🐟 أسماك 🐟',
                content: [
                  {
                    id: 0,
                    name: 'السمك المشوي',
                    price: '32',
                  },
                ],
              },
              {
                id: 4,
                name: '🍗 دواجن  🍗',
                content: [
                  {
                    id: 0,
                    name: 'فخذ الدجاج',
                    price: '19',
                  },
                  {
                    id: 1,
                    name: 'سوبريم دجاج',
                    price: '20',
                  },
                  {
                    id: 2,
                    name: 'قطع الدجاج',
                    price: '19',
                  },
                  {
                    id: 3,
                    name: 'اسكالوب ديك رومي مشوي',
                    price: '19',
                  },
                  {
                    id: 4,
                    name: 'اسكالوب ديك رومي المخبوزة',
                    price: '20',
                  },
                  {
                    id: 5,
                    name: 'كوردون بلو',
                    price: '20',
                  },
                  {
                    id: 6,
                    name: 'ديك رومي سيخ',
                    price: '20',
                  },
                ],
              },
              {
                id: 5,
                name: '🥩 اللحوم 🥩',
                content: [
                  {
                    id: 0,
                    name: 'أكمل مرقيز',
                    price: '24',
                  },
                  {
                    id: 1,
                    name: 'مشاوي مشكلة',
                    price: '45',
                  },
                  {
                    id: 2,
                    name: 'بولونيز معكرونة',
                    price: '30',
                  },
                ],
              },
              {
                id: 6,
                name: '🍔 الهامبرغر 🍔',
                content: [
                  {
                    id: 0,
                    name: 'همبرغر لحم',
                    price: '17',
                  },
                  {
                    id: 1,
                    name: 'همبرغر ديك رومي',
                    price: '16',
                  },
                ],
              },
              /*{
                id: 7,
                name: '🥪 ساندويتش 🥪',
                content: [
                  {
                    id: 0,
                    name: 'ساندوتش سلامي',
                    price: '6',
                  },
                  {
                    id: 1,
                    name: 'ساندوتش تونة',
                    price: '9',
                  },
                  {
                    id: 2,
                    name: 'ساندوتش اسكالوب',
                    price: '8',
                  },
                ],
              },*/
              {
                id: 8,
                name: '🍕 بيتزا 🍕',
                content: [
                  {
                    id: 0,
                    name: 'بيتزا مارجريتا',
                    price: '15',
                  },
                  {
                    id: 1,
                    name: 'بيتزا لحم سلامي',
                    price: '18',
                  },
                  {
                    id: 2,
                    name: 'بيتزا بالبيبروني',
                    price: '18',
                  },
                  {
                    id: 3,
                    name: 'بيتزا الفطر',
                    price: '18',
                  },
                  {
                    id: 4,
                    name: 'بيتزا نباتي',
                    price: '18',
                  },
                  {
                    id: 5,
                    name: 'بيتزا اسكالوب',
                    price: '20',
                  },
                  {
                    id: 6,
                    name: 'بيتزا تونا',
                    price: '24',
                  },
                  {
                    id: 7,
                    name: 'شيف بيتزا',
                    price: '25',
                  },
                  {
                    id: 8,
                    name: 'بيتزا بولونيز',
                    price: '30',
                  },
                ],
              },
              {
                id: 9,
                name: '🍎 وجبة أخيرة 🍎',
                content: [
                  {
                    id: 0,
                    name: 'فواكه موسمية',
                    price: '8',
                  },
                ],
              },
              {
                id: 10,
                name: '🥤 مشروبات مثلجة 🥤',
                content: [
                  {
                    id: 0,
                    name: 'علبة غازية',
                    price: '4',
                  },
                  {
                    id: 1,
                    name: 'صافية 1.5 لتر',
                    price: '3',
                  },
                  {
                    id: 2,
                    name: 'صافية 0.5 لتر',
                    price: '2',
                  },
                  {
                    id: 3,
                    name: 'شارك',
                    price: '10',
                  },
                  {
                    id: 4,
                    name: 'علبة سيلتيا',
                    price: '7.5',
                  },
                  {
                    id: 5,
                    name: 'علبة باكس',
                    price: '7.5',
                  },
                  {
                    id: 6,
                    name: 'علبة هاينكن',
                    price: '7.5',
                  },
                ],
              },
            ],
          },
          {
            id: 2,
            name: 'الوجبات الخفيفة',
            imgUrl: require('@/assets/acquapalace-kantaoui-tunisia_03.jpeg'),
            description:
              ".تمتعوا بمطاعمنا الممتدة على فضاء شاسع و بمختلف نقاط البيع المتوفرة بها و منها ثلاثة مطاعم للوجبات الخفيفة 'القرصان' , ’اتلانتس’ و 'حورية البحر' موزعة على كامل المنتزه، تتماشى مع إختيارتكم و إمكانياتكم. أما إذا كنتم لا تحبذون الجلوس للأكل فلكم حرية إختيار الخدمة السريعة التي تمكنكم من حمل وجبتكم. لقد وضعنا على ذمة حرفائنا كل الخدمات التي يحتاجونها، أطلبوا مشروبا منعشا أو وجبة تتمتعون بها تحت شمسية أو في الظلال أو بعض الوجبات الخفيفة الممتعة. ستجدون كل خياراتكم بالأثمان التي تناسبكم كي نمكن كل حريف مما يبحث عنه و ما يروق له و هو يقضي هذه اللحظات الممتعة و ذلك بعد أن يستجمع قواه لمتابعة هذا اليوم الجميل",
            alt_text: '',
            menu_text: 'قائمة',
            menus: [
              {
                id: 0,
                name: '☕ مشروبات ساخنة ☕',
                content: [
                  {
                    id: 0,
                    name: 'اسبرس',
                    price: '4',
                  },
                  {
                    id: 1,
                    name: 'كابوسين',
                    price: '4.5',
                  },
                  {
                    id: 2,
                    name: 'قهوة كريم',
                    price: '5',
                  },
                  {
                    id: 3,
                    name: 'شوكولاتة بالحليب',
                    price: '3.5',
                  },
                ],
              },
              {
                id: 1,
                name: '🥤 مشروبات مثلجة 🥤',
                content: [
                  {
                    id: 0,
                    name: 'علبة غازية',
                    price: '4',
                  },
                  {
                    id: 1,
                    name: 'صافية 1.5 لتر',
                    price: '3',
                  },
                  {
                    id: 2,
                    name: 'صافية 0.5 لتر',
                    price: '2',
                  },
                  {
                    id: 3,
                    name: 'شارك',
                    price: '10',
                  },
                  {
                    id: 4,
                    name: 'علبة سيلتيا',
                    price: '7.5',
                  },
                  {
                    id: 5,
                    name: 'علبة باكس',
                    price: '7.5',
                  },
                  {
                    id: 6,
                    name: 'علبة هاينكن',
                    price: '7.5',
                  },
                ],
              },
              {
                id: 2,
                name: '🥪 ساندويتش 🥪',
                content: [
                  {
                    id: 0,
                    name: 'ساندوتش سلامي',
                    price: '7',
                  },
                  {
                    id: 1,
                    name: 'ساندوتش تونة',
                    price: '10',
                  },
                  {
                    id: 2,
                    name: 'ساندوتش اسكالوب',
                    price: '9',
                  },
                ],
              },
              {
                id: 3,
                name: '🌯 بانيني 🌯',
                content: [
                  {
                    id: 0,
                    name: 'بانيني بالجبنة',
                    price: '7',
                  },
                  {
                    id: 1,
                    name: 'بانيني سلامي و جبنة',
                    price: '7.5',
                  },
                  {
                    id: 2,
                    name: 'بانيني لحم وجبن',
                    price: '8',
                  },
                  {
                    id: 3,
                    name: 'تونا بانيني',
                    price: '10',
                  },
                  {
                    id: 4,
                    name: 'كستلاتة بانيني',
                    price: '9',
                  },
                  {
                    id: 5,
                    name: 'بانيني خاص',
                    price: '10.5',
                  },
                ],
              },
              {
                id: 4,
                name: '🥞 كريب 🥞',
                content: [
                  /*{
                    id: 0,
                    name: 'كريب السكر',
                    price: '3.5',
                  },*/
                  {
                    id: 1,
                    name: 'كريب شوكولاتة',
                    price: '6',
                  },
                  {
                    id: 2,
                    name: 'كريب نوتيلا',
                    price: '9',
                  },
                  {
                    id: 3,
                    name: 'كريب جبن',
                    price: '6',
                  },
                  {
                    id: 4,
                    name: 'كريب سلامي و جبنة',
                    price: '7',
                  },
                  {
                    id: 5,
                    name: 'كريب لحم وجبن',
                    price: '8',
                  },
                  {
                    id: 6,
                    name: 'كريب اسكالوب وجبنة',
                    price: '8.5',
                  },
                  {
                    id: 7,
                    name: 'كريب التونة والجبن',
                    price: '10',
                  },
                  {
                    id: 8,
                    name: 'كريب خاص',
                    price: '11',
                  },
                ],
              },
              {
                id: 5,
                name: '🍦 مثلجات 🍦',
                content: [
                  {
                    id: 0,
                    name: 'ألاسكا',
                    price: '1.8',
                  },
                  {
                    id: 1,
                    name: 'بالو',
                    price: '1.8',
                  },
                  /*{
                    id: 2,
                    name: 'مهرج',
                    price: '2,2',
                  },
                  {
                    id: 3,
                    name: 'شارلوت',
                    price: '2,2',
                  },*/
                  {
                    id: 4,
                    name: 'ماجتوب',
                    price: '2,8',
                  },
                  {
                    id: 5,
                    name: 'تويستر',
                    price: '2,8',
                  },
                  /*{
                    id: 6,
                    name: 'توم و جيري',
                    price: '1,5',
                  },*/
                  {
                    id: 7,
                    name: 'بيكسو',
                    price: '2.5',
                  },
                  {
                    id: 8,
                    name: 'فيوستا',
                    price: '2,5',
                  },
                  {
                    id: 9,
                    name: 'أميغو',
                    price: '3',
                  },
                  {
                    id: 10,
                    name: 'اميجو ماكس',
                    price: '4',
                  },
                  {
                    id: 11,
                    name: 'غولد',
                    price: '4',
                  },
                  /*{
                    id: 12,
                    name: 'XL',
                    price: '3,7',
                  },*/
                ],
              },
              {
                id: 5,
                name: '🍫 وجبات خفيفة 🍫',
                content: [
                  {
                    id: 0,
                    name: 'شيبس 16 جرام',
                    price: '2.5',
                  },
                  /*{
                    id: 1,
                    name: 'شيبس 75 جرام',
                    price: '4',
                  },*/
                  {
                    id: 2,
                    name: 'غوفراة',
                    price: '2.5',
                  },
                  {
                    id: 3,
                    name: 'سابليتو',
                    price: '3.5',
                  },
                  {
                    id: 4,
                    name: 'زبادي للشرب',
                    price: '3.5',
                  },
                  {
                    id: 5,
                    name: 'فيتاليو',
                    price: '2',
                  },
                  {
                    id: 6,
                    name: 'عصير 25سل',
                    price: '3.5',
                  },
                  /*{
                    id: 4,
                    name: 'مارس',
                    price: '3.5',
                  },
                  {
                    id: 5,
                    name: 'سنيكرز',
                    price: '3.5',
                  },*/
                ],
              },
              {
                id: 6,
                name: '🚬 منتجات أخرى 🚬',
                content: [
                  {
                    id: 0,
                    name: 'ولاعة',
                    price: '1.5',
                  },
                ],
              },
            ],
          },
          {
            id: 3,
            name: 'محل/متجر',
            imgUrl: require('@/assets/d8575c18d5c8396bb3826cb1ad9922bc.jpeg'),
            description:
              '.لقد وضعنا تحت تصرفكم متجرا للمشتريات الطارئة و اللتي لم تكن في الحسبان، فلو نسيتم ملابس السباحة، العوامة، المرهم الشمسي، السجائر فبإمكانكم الحصول على كل ما تحتاجونه',
            alt_text: '',
            menu_text: 'قائمة',
            menus: [
              {
                id: 0,
                name: 'عوامات',
                content: [
                  {
                    id: 0,
                    name: 'صدرية قابلة للنفخ للأطفال',
                    price: '15',
                  },
                  {
                    id: 1,
                    name: 'عواما حلقة لأطفال 50سم',
                    price: '5',
                  },
                  {
                    id: 2,
                    name: 'عواما حلقة لأطفال 60سم',
                    price: '6',
                  },
                  {
                    id: 3,
                    name: 'عواما حلقة 1.22م',
                    price: '30',
                  },
                  {
                    id: 4,
                    name: 'زوج من شارات اليد',
                    price: '6',
                  },
                ],
              },
              {
                id: 1,
                name: '🩱 لباس السباحة 🩱',
                content: [
                  {
                    id: 0,
                    name: 'سيكليزم',
                    price: '15',
                  },
                  {
                    id: 1,
                    name: 'بيكيني',
                    price: '15',
                  },
                  {
                    id: 2,
                    name: 'بوركيني',
                    price: '65',
                  },
                  {
                    id: 3,
                    name: 'شورت سباحة للرجال',
                    price: '20-15',
                  },
                  {
                    id: 4,
                    name: 'شورت سباحة للنساء',
                    price: '15',
                  },
                  {
                    id: 5,
                    name: 'شورت سباحة للطفل',
                    price: '18',
                  },
                  {
                    id: 6,
                    name: 'ملابس السباحة للفتاة',
                    price: '50-30-20',
                  },
                ],
              },
              {
                id: 2,
                name: '👗 ملابس الشاطئ 👗',
                content: [
                  {
                    id: 0,
                    name: 'غطاء التنورة',
                    price: '15',
                  },
                  {
                    id: 1,
                    name: 'فستان الشاطئ',
                    price: '30-18',
                  },
                  {
                    id: 2,
                    name: 'منشفة',
                    price: '13',
                  },
                  {
                    id: 3,
                    name: 'شاطئ جبا للمرأة',
                    price: '35',
                  },
                  {
                    id: 4,
                    name: 'تي شيرت',
                    price: '15-12-10',
                  },
                  {
                    id: 5,
                    name: 'قبعة امرأة',
                    price: '15',
                  },
                  {
                    id: 6,
                    name: 'قبعة الصبي',
                    price: '12',
                  },
                  {
                    id: 7,
                    name: 'قبعة فتاة',
                    price: '12',
                  },
                ],
              },
              {
                id: 3,
                name: '🍫 وجبات خفيفة 🍫',
                content: [
                  {
                    id: 0,
                    name: 'شيبس 16 جرام',
                    price: '1.5',
                  },
                  {
                    id: 1,
                    name: 'شيبس 75 جرام',
                    price: '3.5',
                  },
                  {
                    id: 2,
                    name: 'غوفراة',
                    price: '1.2',
                  },
                  {
                    id: 3,
                    name: 'سابليتو',
                    price: '2.5',
                  },
                  {
                    id: 4,
                    name: 'مارس',
                    price: '3.5',
                  },
                  {
                    id: 5,
                    name: 'سنيكرز',
                    price: '3.5',
                  },
                ],
              },
              {
                id: 4,
                name: '🧴 كريم الشمس  🧴',
                content: [
                  {
                    id: 0,
                    name: 'كريم الشمس نيفيا 200 مل',
                    price: '35',
                  },
                  {
                    id: 1,
                    name: 'كريم الشمس نيفيا صن 50+ 200 مل',
                    price: '60',
                  },
                  {
                    id: 2,
                    name: 'كريم الشمس نيفيا صن 30+ 200 مل',
                    price: '50',
                  },
                ],
              },
            ],
          },
        ],
      },
    };
  },
  mutations: {},
  getters: {
    getServices(state, getters, rootState, rootGetters) {
      return state.services[rootGetters['lang/getCurrentLang']];
    },
  },
  actions: {},
};
export default services;
