import Media from '@/utils/media.js';

const attractions = {
  namespaced: true,
  state() {
    return {
      attractions: {
        fr: [
          {
            id: 0,
            name: 'Rocket',
            medias: [
              new Media(
                0,
                require('@/assets/DSC_0759-2.jpeg'),
                'image',
                '',
                ''
              ),
              new Media(1, 'XGQDCb3WSwg', 'video', '', ''),
              new Media(2, require('@/assets/DSC_0784.jpeg'), 'image', '', ''),
              new Media(
                3,
                require('@/assets/FallRocket-2.jpeg'),
                'image',
                '',
                ''
              ),
            ],
            brief: 'Le plus haut toboggan en TUNISIE',
            description: 'Le plus haut toboggan en TUNISIE',
            rules: [
              {
                id: 0,
                imgUrl: require('@/assets/rules/RocketYoWeight.jpeg'),
                desc: 'Poids maximum 120 kg - âge minimum 7 ans',
              },
              {
                id: 1,
                imgUrl: require('@/assets/rules/cardiac.jpeg'),
                desc: 'Interdit aux personnes avec des troubles cardiaques',
              },
              {
                id: 2,
                imgUrl: require('@/assets/rules/Preg.jpeg'),
                desc: 'Interdit aux femmes enceintes',
              },
            ],
            stats: [
              {
                id: 0,
                name: 'Vitesse',
                value: 100,
                color: 'var(--speed)',
              },
              {
                id: 1,
                name: 'Sensations Fortes',
                value: 95,
                color: 'var(--thrill)',
              },
              {
                id: 2,
                name: 'relaxation',
                value: 0,
                color: 'var(--relaxation)',
              },
            ],
          },
          {
            id: 1,
            name: 'Crazy River',
            medias: [
              new Media(
                0,
                require('@/assets/DSC_0085_1-3.jpeg'),
                'image',
                '',
                ''
              ),
              new Media(1, 'XGQDCb3WSwg', 'video', '', ''),
              new Media(2, require('@/assets/DSC_0693.jpeg'), 'image', '', ''),
              new Media(
                3,
                require('@/assets/DSC_9937-2.jpeg'),
                'image',
                '',
                ''
              ),
            ],
            brief: "Frisson garantie LE MEILLEUR DE L'ACQUA PALACE",
            description: 'Nouvelle attraction de la saison 2020',
            rules: [
              {
                id: 0,
                imgUrl: require('@/assets/rules/FloadUse.jpeg'),
                desc: "L'utilisation de la bouée double est obligatoire",
              },
              {
                id: 1,
                imgUrl: require('@/assets/rules/2ByFloat.jpeg'),
                desc: 'Obligatoirement deux personnes par bouée',
              },
              {
                id: 2,
                imgUrl: require('@/assets/rules/Kid8Yo-2.jpeg'),
                desc: 'Les enfants de moins de 7 ans doivent être accompagnés',
              },
              {
                id: 3,
                imgUrl: require('@/assets/rules/Preg.jpeg'),
                desc: 'Interdit aux femmes enceintes',
              },
            ],
            stats: [
              {
                id: 0,
                name: 'Vitesse',
                value: 85,
                color: 'var(--speed)',
              },
              {
                id: 1,
                name: 'Sensations Fortes',
                value: 90,
                color: 'var(--thrill)',
              },
              {
                id: 2,
                name: 'relaxation',
                value: 0,
                color: 'var(--relaxation)',
              },
            ],
          },
          {
            id: 2,
            name: 'Blackhole',
            medias: [
              new Media(0, require('@/assets/image9.jpeg'), 'image', '', ''),
              new Media(1, '-J3sq1Co-JA', 'video', '', ''),
            ],
            brief: 'Le grand frisson !',
            description:
              'Soyez le 1er à plonger en venant découvrir notre attraction aquatique le "BlackHole", osez tenter le grand frisson en glissant sur ce vertigineux toboggan, l’un des plus hauts en Tunisie et en exclusivité uniquement à l\'Acqua Palace, vous aurez tout le temps pendant la montée de ses 17 mètres de hauteur, de vous demander ce que vous faites dans cette galère !!!! Là, un conseil, prenez une grande respiration car la descente de 150 mètres de long dans un gigantesque tunnel pourrait bien vous couper le souffle !!!',
            rules: [
              {
                id: 0,
                imgUrl: require('@/assets/rules/FloadUse.jpeg'),
                desc: "L'utilisation de la bouée double est obligatoire",
              },
              {
                id: 1,
                imgUrl: require('@/assets/rules/2ByFloat.jpeg'),
                desc: 'Obligatoirement deux personnes par bouée',
              },
              {
                id: 2,
                imgUrl: require('@/assets/rules/Kid8Yo-2.jpeg'),
                desc: 'Les enfants de moins de 7 ans doivent être accompagnés',
              },
              {
                id: 3,
                imgUrl: require('@/assets/rules/Preg.jpeg'),
                desc: 'Interdit aux femmes enceintes',
              },
            ],
            stats: [
              {
                id: 0,
                name: 'Vitesse',
                value: 85,
                color: 'var(--speed)',
              },
              {
                id: 1,
                name: 'Sensations Fortes',
                value: 90,
                color: 'var(--thrill)',
              },
              {
                id: 2,
                name: 'relaxation',
                value: 10,
                color: 'var(--relaxation)',
              },
            ],
          },
          {
            id: 3,
            name: 'Space Boat',
            medias: [
              new Media(0, require('@/assets/_K8A7795.jpeg'), 'image', '', ''),
              new Media(1, 'IUQwlKupXtQ', 'video', '', ''),
              new Media(
                2,
                require('@/assets/DSC_0434-2.jpeg'),
                'image',
                '',
                ''
              ),
            ],
            brief: "Surtout n'ayez pas peur !",
            description:
              "Démontrez votre courage dans cette renversante expérience du toboggan le Space Boat du haut de ses 12 mètres de hauteur et avec ses changements de niveaux vertigineux, défiez vos proches et vos amis dans cette divertissante et rafraîchissante attraction aquatique pleine d'aventure et d'émotion à vous couper le souffle, qui sera le toboggan le plus renversant de l'été. De la dynamite !!",
            rules: [
              {
                id: 0,
                imgUrl: require('@/assets/rules/FloadUse.jpeg'),
                desc: "L'utilisation de la bouée double est obligatoire",
              },
              {
                id: 1,
                imgUrl: require('@/assets/rules/2ByFloat.jpeg'),
                desc: 'Obligatoirement deux personnes par bouée',
              },
              {
                id: 2,
                imgUrl: require('@/assets/rules/Kid8Yo-2.jpeg'),
                desc: 'Les enfants de moins de 7 ans doivent être accompagnés',
              },
              {
                id: 3,
                imgUrl: require('@/assets/rules/Preg.jpeg'),
                desc: 'Interdit aux femmes enceintes',
              },
            ],
            stats: [
              {
                id: 0,
                name: 'Vitesse',
                value: 80,
                color: 'var(--speed)',
              },
              {
                id: 1,
                name: 'Sensations Fortes',
                value: 75,
                color: 'var(--thrill)',
              },
              {
                id: 2,
                name: 'relaxation',
                value: 20,
                color: 'var(--relaxation)',
              },
            ],
          },
          {
            id: 4,
            name: 'Foam',
            medias: [
              new Media(
                0,
                require('@/assets/acqua-palace_toboggan-foam.jpeg'),
                'image',
                '',
                ''
              ),
              new Media(1, '0OFGekDaVwY', 'video', '', ''),
              new Media(
                2,
                require('@/assets/DSC_0411-3.jpeg'),
                'image',
                '',
                ''
              ),
            ],
            brief: 'La glisse à volonté !!',
            description:
              'Reprenez vite votre souffle dans les 4 pistes du toboggan le FOAM, découvrez une manière amusante de vous rafraîchir et de vous amusez en groupe, une attraction à partager absolument entre amis. Après les sensations fortes, n’ayez surtout pas peur des éclaboussures, là est la beauté du jeu. :)',
            rules: [
              {
                id: 0,
                imgUrl: require('@/assets/rules/SimHeight.jpeg'),
                desc: 'Personnes supérieurs à 140 cm ou enfants sachant nager',
              },
              {
                id: 2,
                imgUrl: require('@/assets/rules/Preg.jpeg'),
                desc: 'Interdit aux femmes enceintes',
              },
            ],
            stats: [
              {
                id: 0,
                name: 'Vitesse',
                value: 50,
                color: 'var(--speed)',
              },
              {
                id: 1,
                name: 'Sensations Fortes',
                value: 60,
                color: 'var(--thrill)',
              },
              {
                id: 2,
                name: 'relaxation',
                value: 30,
                color: 'var(--relaxation)',
              },
            ],
          },
          {
            id: 5,
            name: 'Kamikaze',
            medias: [
              new Media(
                0,
                require('@/assets/acqua-palace_drop.jpeg'),
                'image',
                '',
                ''
              ),
              new Media(1, 'RGszJmwxJyc', 'video', '', ''),
              new Media(2, require('@/assets/image6.jpeg'), 'image', '', ''),
              new Media(
                3,
                require('@/assets/DSC_9974-2.jpeg'),
                'image',
                '',
                ''
              ),
            ],
            brief: 'La glisse extrème',
            description:
              "Et il vous en faudra pour ne pas trembler une fois installé tout en haut du Kamikaze, avec sa hauteur de 8 mètres et de sa vitesse atteignant les 50 km/h, Un grand plongeon est à l'arrivée de cette descente vertigineuse. Le Toboggan KAMIKAZE vous procurera des sensations à couper le souffle... Et vous allez en redemander !! ",
            rules: [
              {
                id: 0,
                imgUrl: require('@/assets/rules/SimHeight.jpeg'),
                desc: 'Personnes supérieurs à 140 cm ou enfants sachant nager',
              },
              {
                id: 1,
                imgUrl: require('@/assets/rules/Preg.jpeg'),
                desc: 'Interdit aux femmes enceintes',
              },
            ],
            stats: [
              {
                id: 0,
                name: 'Vitesse',
                value: 70,
                color: 'var(--speed)',
              },
              {
                id: 1,
                name: 'Sensations Fortes',
                value: 85,
                color: 'var(--thrill)',
              },
              {
                id: 2,
                name: 'relaxation',
                value: 30,
                color: 'var(--relaxation)',
              },
            ],
          },
          {
            id: 6,
            name: 'Drop',
            medias: [
              new Media(
                0,
                require('@/assets/acqua-palace_44.jpeg'),
                'image',
                '',
                ''
              ),
              new Media(1, 'DrkQGEhJTgE', 'video', '', ''),
              new Media(
                2,
                require('@/assets/DSC_9581-2.jpeg'),
                'image',
                '',
                ''
              ),
              new Media(3, require('@/assets/15.jpeg'), 'image', '', ''),
            ],
            brief: 'Combinaison entre glisse et détente',
            description:
              'Embarquez à bord du toboggan le Drop, préparez-vous à être chahutés de virage en virage, un tourbillon de fou rires et éclaboussures garantis sont du voyage. Une descente de 8m, 100% fun.',
            rules: [
              {
                id: 0,
                imgUrl: require('@/assets/rules/SimHeight.jpeg'),
                desc: 'Personnes supérieurs à 140 cm ou enfants sachant nager',
              },
              {
                id: 1,
                imgUrl: require('@/assets/rules/Parent&Kid.jpeg'),
                desc: 'Vous pouvez accompagner votre enfant',
              },
              {
                id: 2,
                imgUrl: require('@/assets/rules/Preg.jpeg'),
                desc: 'Interdit aux femmes enceintes',
              },
            ],
            stats: [
              {
                id: 0,
                name: 'Vitesse',
                value: 50,
                color: 'var(--speed)',
              },
              {
                id: 1,
                name: 'Sensations Fortes',
                value: 40,
                color: 'var(--thrill)',
              },
              {
                id: 2,
                name: 'relaxation',
                value: 70,
                color: 'var(--relaxation)',
              },
            ],
          },
          {
            id: 7,
            name: 'Piscine à vagues',
            medias: [
              new Media(
                0,
                require('@/assets/acqua-palace_piscine_a_vagues.jpeg'),
                'image',
                '',
                ''
              ),
              new Media(1, 'bAm80E5Rd9g', 'video', '', ''),
              new Media(
                2,
                require('@/assets/DSC_7414-1-3.jpeg'),
                'image',
                '',
                ''
              ),
              new Media(
                3,
                require('@/assets/DSC_7379-2.jpeg'),
                'image',
                '',
                ''
              ),
            ],
            brief: 'Des vagues comme à la mer',
            description:
              "Calmes ! Ou plutôt agitées ! Il y en a pour tous les goûts ! L’essentiel, c'est de s'amuser. Avec des creux de deux mètres sur une surface de 2000m² d'eau, c'est la plus grande piscine à vagues en Tunisie. Mais aussi, des espaces vous attendent pour profiter au maximum de l'eau rafraîchissante pour toute la famille. Des bains de soleil au milieu de nos espaces verts tout autour de la piscine. Accompagné d'une bonne ambiance avec des rythmes exotiques!! Laissez la cadence de la musique parcourir votre corps et vous faire vibrer.",
            rules: [
              {
                id: 0,
                imgUrl: require('@/assets/rules/Kid12Yo-2.jpeg'),
                desc: 'Les enfants de moins de 12 ans doivent être accompagnés',
              },
            ],
            stats: [
              {
                id: 0,
                name: 'Vitesse',
                value: 0,
                color: 'var(--speed)',
              },
              {
                id: 1,
                name: 'Sensations Fortes',
                value: 50,
                color: 'var(--thrill)',
              },
              {
                id: 2,
                name: 'relaxation',
                value: 80,
                color: 'var(--relaxation)',
              },
            ],
          },
          {
            id: 8,
            name: 'Rivière Lente',
            medias: [
              new Media(
                0,
                require('@/assets/acqua-palace_acqua-palace__K8A7666.jpeg'),
                'image',
                '',
                ''
              ),
              new Media(1, 'KEdNs2g_Eug', 'video', '', ''),
              new Media(2, require('@/assets/DSC_0032.jpeg'), 'image', '', ''),
            ],
            brief: 'La promenade enchanteresse',
            description:
              "Cette promenade sur une rivière de 200 mètres de long, bordée de verdure et truffée d’effets aquatiques vous fera le plus grand bien, une vraie relaxation et détente pour le corps et l’esprit. Mais ce fantastique tour en bouée n'est pas aussi tranquille qu'il n'y paraît, alors accrochez-vous bien pour le passage, osez traverser ses espiègles jets d'eau rafraîchissants, une manière amusante pour s’éclater.",
            rules: [
              {
                id: 0,
                imgUrl: require('@/assets/rules/Kid8Yo-2.jpeg'),
                desc: 'Les enfants de moins de 7 ans doivent être accompagnés',
              },
            ],
            stats: [
              {
                id: 0,
                name: 'Vitesse',
                value: 10,
                color: 'var(--speed)',
              },
              {
                id: 1,
                name: 'Sensations Fortes',
                value: 0,
                color: 'var(--thrill)',
              },
              {
                id: 2,
                name: 'relaxation',
                value: 85,
                color: 'var(--relaxation)',
              },
            ],
          },
          {
            id: 9,
            name: 'Acqua Baby',
            medias: [
              new Media(
                0,
                require('@/assets/acqua-palace_DSC_0631.jpeg'),
                'image',
                '',
                ''
              ),
              new Media(
                1,
                require('@/assets/DSC_0042-1.jpeg'),
                'image',
                '',
                ''
              ),
              new Media(
                2,
                require('@/assets/DSC_9905-1.jpeg'),
                'image',
                '',
                ''
              ),
            ],
            brief: 'Le Mini Parc pour vos enfants, nouveauté 2020',
            description:
              "L'Acqua BABY est le paradis des enfants. Des minis attractions qui feront le plus grand plaisir des plus petits ! Avec Le mini FOAM, et s'ajoute nos 5 nouvelles attractions pour la saison 2020 avec le mini DROP, MEGA SLIDE, BATEAU PIRATE, MINI PIEUVRE ET LE CHAMPIGNON et une piscine de 360 m² et une profondeur de 0.4 mètres, une expérience enchanteresse pour les enfants et l'endroit parfait pour qu'ils s'amusent en toute sécurité !",
            rules: [
              {
                id: 0,
                imgUrl: require('@/assets/rules/Kids-140.jpeg'),
                desc: 'Enfants de moins de 140 cm seulement',
              },
            ],
            stats: [
              {
                id: 0,
                name: 'Vitesse',
                value: 20,
                color: 'var(--speed)',
              },
              {
                id: 1,
                name: 'Sensations Fortes',
                value: 100,
                color: 'var(--thrill)',
              },
              {
                id: 2,
                name: 'relaxation',
                value: 40,
                color: 'var(--relaxation)',
              },
            ],
          },
        ],
        en: [
          {
            id: 0,
            name: 'Rocket',
            medias: [
              new Media(
                0,
                require('@/assets/DSC_0759-2.jpeg'),
                'image',
                '',
                ''
              ),
              new Media(1, 'XGQDCb3WSwg', 'video', '', ''),
              new Media(2, require('@/assets/DSC_0784.jpeg'), 'image', '', ''),
              new Media(
                3,
                require('@/assets/FallRocket-2.jpeg'),
                'image',
                '',
                ''
              ),
            ],
            brief: 'The highest slide in TUNISIA',
            description: 'The highest slide in TUNISIA.',
            rules: [
              {
                id: 0,
                imgUrl: require('@/assets/rules/RocketYoWeight.jpeg'),
                desc: 'Maximum weight of 120 kg – Minimum Age: 7',
              },
              {
                id: 1,
                imgUrl: require('@/assets/rules/cardiac.jpeg'),
                desc: 'Prohibited for people with heart conditions',
              },
              {
                id: 2,
                imgUrl: require('@/assets/rules/Preg.jpeg'),
                desc: 'Prohibited for pregnant women',
              },
            ],
            stats: [
              {
                id: 0,
                name: 'Speed',
                value: 100,
                color: 'var(--speed)',
              },
              {
                id: 1,
                name: 'Strong emotions',
                value: 95,
                color: 'var(--thrill)',
              },
              {
                id: 2,
                name: 'relaxation',
                value: 0,
                color: 'var(--relaxation)',
              },
            ],
          },
          {
            id: 1,
            name: 'Crazy River',
            medias: [
              new Media(
                0,
                require('@/assets/DSC_0085_1-3.jpeg'),
                'image',
                '',
                ''
              ),
              new Media(1, 'XGQDCb3WSwg', 'video', '', ''),
              new Media(2, require('@/assets/DSC_0693.jpeg'), 'image', '', ''),
              new Media(
                3,
                require('@/assets/DSC_9937-2.jpeg'),
                'image',
                '',
                ''
              ),
            ],
            brief: 'Goosebumps guaranteed ! THE BEST OF ACQUA PALACE',
            description: 'THE new attraction of the 2020 season.',
            rules: [
              {
                id: 0,
                imgUrl: require('@/assets/rules/FloadUse.jpeg'),
                desc: 'The use of the double float is obligatory',
              },
              {
                id: 1,
                imgUrl: require('@/assets/rules/2ByFloat.jpeg'),
                desc: 'Two people per float is obligatory',
              },
              {
                id: 2,
                imgUrl: require('@/assets/rules/Kid8Yo-2.jpeg'),
                desc: 'Children under the age of 7 must be accompanied',
              },
              {
                id: 3,
                imgUrl: require('@/assets/rules/Preg.jpeg'),
                desc: 'Prohibited for pregnant women',
              },
            ],
            stats: [
              {
                id: 0,
                name: 'Speed',
                value: 85,
                color: 'var(--speed)',
              },
              {
                id: 1,
                name: 'Strong emotions',
                value: 90,
                color: 'var(--thrill)',
              },
              {
                id: 2,
                name: 'relaxation',
                value: 0,
                color: 'var(--relaxation)',
              },
            ],
          },
          {
            id: 2,
            name: 'Blackhole',
            medias: [
              new Media(0, require('@/assets/image9.jpeg'), 'image', '', ''),
              new Media(1, '-J3sq1Co-JA', 'video', '', ''),
            ],
            brief: 'Full of thrill',
            description:
              'Become the first to plunge while discovering our aquatic attraction the “Black Hole”. Dare to experience the biggest thrill while you’re at this head-spinning slide. It is one of the highest in Tunisia, exclusive only to Acqua Palace. You will have plenty of time ascending its height of 17 metres to ask yourself: what got you in this mess!!!! Here, a piece of advice, take your time to breathe, for the descent of close to 150 metres all along a gigantic tunnel might as well take your breath away.',
            rules: [
              {
                id: 0,
                imgUrl: require('@/assets/rules/FloadUse.jpeg'),
                desc: 'The use of the double float is obligatory',
              },
              {
                id: 1,
                imgUrl: require('@/assets/rules/2ByFloat.jpeg'),
                desc: 'Two people per float is obligatory',
              },
              {
                id: 2,
                imgUrl: require('@/assets/rules/Kid8Yo-2.jpeg'),
                desc: 'Children under the age of 7 must be accompanied',
              },
              {
                id: 3,
                imgUrl: require('@/assets/rules/Preg.jpeg'),
                desc: 'Prohibited for pregnant women',
              },
            ],
            stats: [
              {
                id: 0,
                name: 'Speed',
                value: 85,
                color: 'var(--speed)',
              },
              {
                id: 1,
                name: 'Strong emotions',
                value: 90,
                color: 'var(--thrill)',
              },
              {
                id: 2,
                name: 'relaxation',
                value: 10,
                color: 'var(--relaxation)',
              },
            ],
          },
          {
            id: 3,
            name: 'Space Boat',
            medias: [
              new Media(0, require('@/assets/_K8A7795.jpeg'), 'image', '', ''),
              new Media(1, 'IUQwlKupXtQ', 'video', '', ''),
              new Media(
                2,
                require('@/assets/DSC_0434-2.jpeg'),
                'image',
                '',
                ''
              ),
            ],
            brief: 'Above all, do not be afraid !',
            description:
              'Demonstrate your courage in this stunning experience of the Space Boat slide. Reaching as high as 12 metres in height alongside some head-spinning levels, challenge your friends and close ones in this entertaining and refreshing aquatic attraction full of adventure and breath-taking emotions. This will be the most stunning slide of the season. It’s pure Dynamite!!',
            rules: [
              {
                id: 0,
                imgUrl: require('@/assets/rules/FloadUse.jpeg'),
                desc: 'The use of the double float is obligatory',
              },
              {
                id: 1,
                imgUrl: require('@/assets/rules/2ByFloat.jpeg'),
                desc: 'Two people per float is obligatory',
              },
              {
                id: 2,
                imgUrl: require('@/assets/rules/Kid8Yo-2.jpeg'),
                desc: 'Children under the age of 7 must be accompanied',
              },
              {
                id: 3,
                imgUrl: require('@/assets/rules/Preg.jpeg'),
                desc: 'Prohibited for pregnant women',
              },
            ],
            stats: [
              {
                id: 0,
                name: 'Speed',
                value: 80,
                color: 'var(--speed)',
              },
              {
                id: 1,
                name: 'Strong emotions',
                value: 75,
                color: 'var(--thrill)',
              },
              {
                id: 2,
                name: 'relaxation',
                value: 20,
                color: 'var(--relaxation)',
              },
            ],
          },
          {
            id: 4,
            name: 'Foam',
            medias: [
              new Media(
                0,
                require('@/assets/acqua-palace_toboggan-foam.jpeg'),
                'image',
                '',
                ''
              ),
              new Media(1, '0OFGekDaVwY', 'video', '', ''),
              new Media(
                2,
                require('@/assets/DSC_0411-3.jpeg'),
                'image',
                '',
                ''
              ),
            ],
            brief: 'Glide at your own will !',
            description:
              'Quickly catch your breath in this 4-track slide: “Foam”. Discover a fun way to be refreshed and have fun in a group, an attraction to absolutely share amongst friends. Following the strong emotions, don’t be scared of the splash it is here where the beauty of the game resides.',
            rules: [
              {
                id: 0,
                imgUrl: require('@/assets/rules/SimHeight.jpeg'),
                desc: 'People above 140 cm or children who can swim',
              },
              {
                id: 3,
                imgUrl: require('@/assets/rules/Preg.jpeg'),
                desc: 'Prohibited for pregnant women',
              },
            ],
            stats: [
              {
                id: 0,
                name: 'Speed',
                value: 50,
                color: 'var(--speed)',
              },
              {
                id: 1,
                name: 'Strong emotions',
                value: 60,
                color: 'var(--thrill)',
              },
              {
                id: 2,
                name: 'relaxation',
                value: 30,
                color: 'var(--relaxation)',
              },
            ],
          },
          {
            id: 5,
            name: 'Kamikaze',
            medias: [
              new Media(
                0,
                require('@/assets/acqua-palace_drop.jpeg'),
                'image',
                '',
                ''
              ),
              new Media(1, 'RGszJmwxJyc', 'video', '', ''),
              new Media(2, require('@/assets/image6.jpeg'), 'image', '', ''),
              new Media(
                3,
                require('@/assets/DSC_9974-2.jpeg'),
                'image',
                '',
                ''
              ),
            ],
            brief: 'An extreme slide',
            description:
              'You must not tremble once you are aboard the peak of the Kamikaze. Reaching a height of 8 metres and a speed of 50km/h, a massive dive awaits you at the end of this dizzying descent. The Kamikaze slide will provide you with breath-taking sensations… You will want to have another go!',
            rules: [
              {
                id: 0,
                imgUrl: require('@/assets/rules/SimHeight.jpeg'),
                desc: 'People above 140 cm or children who can swim',
              },
              {
                id: 1,
                imgUrl: require('@/assets/rules/Preg.jpeg'),
                desc: 'Prohibited for pregnant women',
              },
            ],
            stats: [
              {
                id: 0,
                name: 'Speed',
                value: 70,
                color: 'var(--speed)',
              },
              {
                id: 1,
                name: 'Strong emotions',
                value: 85,
                color: 'var(--thrill)',
              },
              {
                id: 2,
                name: 'relaxation',
                value: 30,
                color: 'var(--relaxation)',
              },
            ],
          },
          {
            id: 6,
            name: 'Drop',
            medias: [
              new Media(
                0,
                require('@/assets/acqua-palace_44.jpeg'),
                'image',
                '',
                ''
              ),
              new Media(1, 'DrkQGEhJTgE', 'video', '', ''),
              new Media(
                2,
                require('@/assets/DSC_9581-2.jpeg'),
                'image',
                '',
                ''
              ),
              new Media(3, require('@/assets/15.jpeg'), 'image', '', ''),
            ],
            brief: 'A combination of sliding and relaxation',
            description:
              'Jump aboard the Drop, and brace yourself to be pushed in all directions. A tornado of giggles and splashes is a guarantee in this voyage. A descent of 8m, 100% fun.',
            rules: [
              {
                id: 0,
                imgUrl: require('@/assets/rules/SimHeight.jpeg'),
                desc: 'People above 140 cm or children who can swim',
              },
              {
                id: 1,
                imgUrl: require('@/assets/rules/Parent&Kid.jpeg'),
                desc: 'You can accompany your child ',
              },
              {
                id: 2,
                imgUrl: require('@/assets/rules/Preg.jpeg'),
                desc: 'Prohibited for pregnant women',
              },
            ],
            stats: [
              {
                id: 0,
                name: 'Speed',
                value: 50,
                color: 'var(--speed)',
              },
              {
                id: 1,
                name: 'Strong emotions',
                value: 40,
                color: 'var(--thrill)',
              },
              {
                id: 2,
                name: 'relaxation',
                value: 70,
                color: 'var(--relaxation)',
              },
            ],
          },
          {
            id: 7,
            name: 'Wave Pool',
            medias: [
              new Media(
                0,
                require('@/assets/acqua-palace_piscine_a_vagues.jpeg'),
                'image',
                '',
                ''
              ),
              new Media(1, 'bAm80E5Rd9g', 'video', '', ''),
              new Media(
                2,
                require('@/assets/DSC_7414-1-3.jpeg'),
                'image',
                '',
                ''
              ),
              new Media(
                3,
                require('@/assets/DSC_7379-2.jpeg'),
                'image',
                '',
                ''
              ),
            ],
            brief: 'Waves just like the sea',
            description:
              'Calm! Or perhaps turbulent! There is everything for everyone! What matters is to have fun. Reaching as deep as 2 metres with a surface of 2000m² of water, it is the biggest wave pool in Tunisia. But also, a bordering space awaits you where you can fully enjoy the refreshing water with all members of the family. Green spaces also allow for sun-bathing all around the wave pool. Let the exotic rhythm of the music run through your body and make you vibrate within this amazing atmosphere. ',
            rules: [
              {
                id: 0,
                imgUrl: require('@/assets/rules/Kid12Yo-2.jpeg'),
                desc: 'Children under the age of 12 must be accompanied',
              },
            ],
            stats: [
              {
                id: 0,
                name: 'Speed',
                value: 0,
                color: 'var(--speed)',
              },
              {
                id: 1,
                name: 'Strong emotions',
                value: 50,
                color: 'var(--thrill)',
              },
              {
                id: 2,
                name: 'relaxation',
                value: 80,
                color: 'var(--relaxation)',
              },
            ],
          },
          {
            id: 8,
            name: 'Lazy River',
            medias: [
              new Media(
                0,
                require('@/assets/acqua-palace_acqua-palace__K8A7666.jpeg'),
                'image',
                '',
                ''
              ),
              new Media(1, 'KEdNs2g_Eug', 'video', '', ''),
              new Media(2, require('@/assets/DSC_0032.jpeg'), 'image', '', ''),
            ],
            brief: 'An enchanting ride.',
            description:
              'These 200 metres-long rides, bordered with greenery and adorned with aquatic effects will be give you the best of time, a true moment of relaxation for body and spirit. But this fantastic tour on floater is not as quiet as it seems, so hang tight for the ride. Dare to cross the jets of refreshing water, an amazing way to have fun.',
            rules: [
              {
                id: 0,
                imgUrl: require('@/assets/rules/Kid8Yo-2.jpeg'),
                desc: 'Children under the age of 7 must be accompanied',
              },
            ],
            stats: [
              {
                id: 0,
                name: 'Speed',
                value: 10,
                color: 'var(--speed)',
              },
              {
                id: 1,
                name: 'Strong emotions',
                value: 0,
                color: 'var(--thrill)',
              },
              {
                id: 2,
                name: 'relaxation',
                value: 85,
                color: 'var(--relaxation)',
              },
            ],
          },
          {
            id: 9,
            name: 'Acqua Baby',
            medias: [
              new Media(
                0,
                require('@/assets/acqua-palace_DSC_0631.jpeg'),
                'image',
                '',
                ''
              ),
              new Media(
                1,
                require('@/assets/DSC_0042-1.jpeg'),
                'image',
                '',
                ''
              ),
              new Media(
                2,
                require('@/assets/DSC_9905-1.jpeg'),
                'image',
                '',
                ''
              ),
            ],
            brief: 'The mini-park for kids. Novelty of 2020',
            description:
              'Aqua Baby is a heaven for kids. Mini-attractions make for the biggest pleasure for the little ones! With the mini-Foam, an addition of 5 new attractions for the 2020 season- mini-Drop, Mega Slide, Pirate Ship, Mini Octopus and The Mushroom-, and a pool of 360m² with a depth of 0.4 metres. Aqua Baby is an enchanting experience for the kids and the perfect place for them to have fun in complete security ! ',
            rules: [
              {
                id: 0,
                imgUrl: require('@/assets/rules/Kids-140.jpeg'),
                desc: 'Only kids under 140 cm',
              },
            ],
            stats: [
              {
                id: 0,
                name: 'Speed',
                value: 20,
                color: 'var(--speed)',
              },
              {
                id: 1,
                name: 'Strong emotions',
                value: 100,
                color: 'var(--thrill)',
              },
              {
                id: 2,
                name: 'relaxation',
                value: 40,
                color: 'var(--relaxation)',
              },
            ],
          },
        ],
        ar: [
          {
            id: 0,
            name: 'روكت',
            medias: [
              new Media(
                0,
                require('@/assets/DSC_0759-2.jpeg'),
                'image',
                '',
                ''
              ),
              new Media(1, 'XGQDCb3WSwg', 'video', '', ''),
              new Media(2, require('@/assets/DSC_0784.jpeg'), 'image', '', ''),
              new Media(
                3,
                require('@/assets/FallRocket-2.jpeg'),
                'image',
                '',
                ''
              ),
            ],
            brief: 'أعلى مزلقة في تونس',
            description: '.أعلى مزلقة في تونس',
            rules: [
              {
                id: 0,
                imgUrl: require('@/assets/rules/RocketYoWeight.jpeg'),
                desc: 'الوزن الأقصى 120 كغ – السن الأدنى 7 سنوات',
              },
              {
                id: 1,
                imgUrl: require('@/assets/rules/cardiac.jpeg'),
                desc: 'يمنع على الأشخاص الذين يعانون إضطرابات في القلب',
              },
              {
                id: 2,
                imgUrl: require('@/assets/rules/Preg.jpeg'),
                desc: 'ممنوع على المرأة الحامل',
              },
            ],
            stats: [
              {
                id: 0,
                name: 'سرعة',
                value: 100,
                color: 'var(--speed)',
              },
              {
                id: 1,
                name: 'أحاسيس قوية',
                value: 95,
                color: 'var(--thrill)',
              },
              {
                id: 2,
                name: 'استرخاء',
                value: 0,
                color: 'var(--relaxation)',
              },
            ],
          },
          {
            id: 1,
            name: 'كريزي ريفر',
            medias: [
              new Media(
                0,
                require('@/assets/DSC_0085_1-3.jpeg'),
                'image',
                '',
                ''
              ),
              new Media(1, 'XGQDCb3WSwg', 'video', '', ''),
              new Media(2, require('@/assets/DSC_0693.jpeg'), 'image', '', ''),
              new Media(
                3,
                require('@/assets/DSC_9937-2.jpeg'),
                'image',
                '',
                ''
              ),
            ],
            brief: 'تشويق مضمون ! أفضل ما يوجد في قصر المياه',
            description: 'تشويق مضمون ! أفضل ما يوجد في قصر المياه – جديد 2020',
            rules: [
              {
                id: 0,
                imgUrl: require('@/assets/rules/FloadUse.jpeg'),
                desc: 'إستعمال العوامة المزدوجة إجباري',
              },
              {
                id: 1,
                imgUrl: require('@/assets/rules/2ByFloat.jpeg'),
                desc: 'وجوب إستعمال عوامة واحدة لكل شخصين (شخصين لكل عوامة)',
              },
              {
                id: 2,
                imgUrl: require('@/assets/rules/Kid8Yo-2.jpeg'),
                desc: 'يجب مرافقة الأطفال دون 7 سنوات',
              },
              {
                id: 3,
                imgUrl: require('@/assets/rules/Preg.jpeg'),
                desc: 'ممنوع على المرأة الحامل',
              },
            ],
            stats: [
              {
                id: 0,
                name: 'سرعة',
                value: 85,
                color: 'var(--speed)',
              },
              {
                id: 1,
                name: 'أحاسيس قوية',
                value: 90,
                color: 'var(--thrill)',
              },
              {
                id: 2,
                name: 'استرخاء',
                value: 0,
                color: 'var(--relaxation)',
              },
            ],
          },
          {
            id: 2,
            name: 'بلاك هول',
            medias: [
              new Media(0, require('@/assets/image9.jpeg'), 'image', '', ''),
              new Media(1, '-J3sq1Co-JA', 'video', '', ''),
            ],
            brief: 'أقوى قشعريرة',
            description:
              'لتكن أنت أول من يغوص و يكتشف ملاهينا المائية بلاك هول. حاول بكل جرأة تجربة أقوى قشعريرة و أنت تنزلق على واحدة من أعلى لعب الإنزلاق في تونس و بصفة حصرية في قصر المياه. ففي الصعود - على إرتفاع 17 متر- سيكون لك متسع من الوقت لتتسائل عن المأزق الذي وضعت نفسك فيه. إذن لك منا النصيحة. خذ نفسا عميقا لأن النزول على طول 150 متر في نفق عملاق قد يحبس أنفاسك',
            rules: [
              {
                id: 0,
                imgUrl: require('@/assets/rules/FloadUse.jpeg'),
                desc: 'إستعمال العوامة المزدوجة إجباري',
              },
              {
                id: 1,
                imgUrl: require('@/assets/rules/2ByFloat.jpeg'),
                desc: 'وجوب إستعمال عوامة واحدة لكل شخصين (شخصين لكل عوامة)',
              },
              {
                id: 2,
                imgUrl: require('@/assets/rules/Kid8Yo-2.jpeg'),
                desc: 'يجب مرافقة الأطفال دون 7 سنوات',
              },
              {
                id: 3,
                imgUrl: require('@/assets/rules/Preg.jpeg'),
                desc: 'ممنوع على المرأة الحامل',
              },
            ],
            stats: [
              {
                id: 0,
                name: 'سرعة',
                value: 85,
                color: 'var(--speed)',
              },
              {
                id: 1,
                name: 'أحاسيس قوية',
                value: 90,
                color: 'var(--thrill)',
              },
              {
                id: 2,
                name: 'استرخاء',
                value: 10,
                color: 'var(--relaxation)',
              },
            ],
          },
          {
            id: 3,
            name: 'سبيس بوات',
            medias: [
              new Media(0, require('@/assets/_K8A7795.jpeg'), 'image', '', ''),
              new Media(1, 'IUQwlKupXtQ', 'video', '', ''),
              new Media(
                2,
                require('@/assets/DSC_0434-2.jpeg'),
                'image',
                '',
                ''
              ),
            ],
            brief: 'لا تشعروا خصوصا بالخوف',
            description:
              'برهنوا على شجاعتكم من خلال هذه التجربة الرائعة لمزلقة  سبيس بوات فمن على إرتفاع 12 متر و بتغير مستوياتها المذهلة، تحدوا أقاربكم و أصحابكم بهذه اللعبة المائية المسلية و المنعشة، المليئة بمغامرة شيقة تحبس الأنفاس. ستكون -دون منازع- أكثر مزلقة مدهشة هذا الصيف. إنها حقا قنبلة الموسم',
            rules: [
              {
                id: 0,
                imgUrl: require('@/assets/rules/FloadUse.jpeg'),
                desc: 'إستعمال العوامة المزدوجة إجباري',
              },
              {
                id: 1,
                imgUrl: require('@/assets/rules/2ByFloat.jpeg'),
                desc: 'وجوب إستعمال عوامة واحدة لكل شخصين (شخصين لكل عوامة)',
              },
              {
                id: 2,
                imgUrl: require('@/assets/rules/Kid8Yo-2.jpeg'),
                desc: 'يجب مرافقة الأطفال دون 7 سنوات',
              },
              {
                id: 3,
                imgUrl: require('@/assets/rules/Preg.jpeg'),
                desc: 'ممنوع على المرأة الحامل',
              },
            ],
            stats: [
              {
                id: 0,
                name: 'سرعة',
                value: 80,
                color: 'var(--speed)',
              },
              {
                id: 1,
                name: 'أحاسيس قوية',
                value: 75,
                color: 'var(--thrill)',
              },
              {
                id: 2,
                name: 'استرخاء',
                value: 20,
                color: 'var(--relaxation)',
              },
            ],
          },
          {
            id: 4,
            name: 'فوم',
            medias: [
              new Media(
                0,
                require('@/assets/acqua-palace_toboggan-foam.jpeg'),
                'image',
                '',
                ''
              ),
              new Media(1, '0OFGekDaVwY', 'video', '', ''),
              new Media(
                2,
                require('@/assets/DSC_0411-3.jpeg'),
                'image',
                '',
                ''
              ),
            ],
            brief: 'إنزلاق حسب الرغبة',
            description:
              'استرجعوا فورا أنفاسكم عبر مسارات المزلقة الأربعة، إكتشفوا طريقة مسلية للإنتعاش و التسلية مع المجموعة، إنه ترفيه مطلق تتقاسمه مع الأصدقاء. بعد هذه الأحاسيس القوية، لا تشعروا خصوصا بالخوف من تناثر المياه، فهنا يكمن جمال اللعبة',
            rules: [
              {
                id: 0,
                imgUrl: require('@/assets/rules/SimHeight.jpeg'),
                desc: 'الأشخاص فوق 140 سم أو الأطفال القادرين على السباحة',
              },
              {
                id: 1,
                imgUrl: require('@/assets/rules/Preg.jpeg'),
                desc: 'ممنوع على المرأة الحامل',
              },
            ],
            stats: [
              {
                id: 0,
                name: 'سرعة',
                value: 50,
                color: 'var(--speed)',
              },
              {
                id: 1,
                name: 'أحاسيس قوية',
                value: 60,
                color: 'var(--thrill)',
              },
              {
                id: 2,
                name: 'استرخاء',
                value: 30,
                color: 'var(--relaxation)',
              },
            ],
          },
          {
            id: 5,
            name: 'كاميكاز',
            medias: [
              new Media(
                0,
                require('@/assets/acqua-palace_drop.jpeg'),
                'image',
                '',
                ''
              ),
              new Media(1, 'RGszJmwxJyc', 'video', '', ''),
              new Media(2, require('@/assets/image6.jpeg'), 'image', '', ''),
              new Media(
                3,
                require('@/assets/DSC_9974-2.jpeg'),
                'image',
                '',
                ''
              ),
            ],
            brief: 'إنزلاق مفرط',
            description:
              'ما إن تثبتوا أنفسكم من أعلى كاميكاز و حتى لا ترتجفوا من علو 8 أمتار و سرعة يمكن أن تصل إلى 50 كم/س، تحتاجون لغطسة كبيرة تضمن هذا النزول المذهل. المزلقة كاميكاز ستسلط عليك أحاسيس تحبس الأنفاس و مع ذلك، ستطلب إعادة التجربة',
            rules: [
              {
                id: 0,
                imgUrl: require('@/assets/rules/SimHeight.jpeg'),
                desc: 'الأشخاص فوق 140 سم أو الأطفال القادرين على السباحة',
              },
              {
                id: 1,
                imgUrl: require('@/assets/rules/Preg.jpeg'),
                desc: 'ممنوع على المرأة الحامل',
              },
            ],
            stats: [
              {
                id: 0,
                name: 'سرعة',
                value: 70,
                color: 'var(--speed)',
              },
              {
                id: 1,
                name: 'أحاسيس قوية',
                value: 85,
                color: 'var(--thrill)',
              },
              {
                id: 2,
                name: 'استرخاء',
                value: 30,
                color: 'var(--relaxation)',
              },
            ],
          },
          {
            id: 6,
            name: 'دروب',
            medias: [
              new Media(
                0,
                require('@/assets/acqua-palace_44.jpeg'),
                'image',
                '',
                ''
              ),
              new Media(1, 'DrkQGEhJTgE', 'video', '', ''),
              new Media(
                2,
                require('@/assets/DSC_9581-2.jpeg'),
                'image',
                '',
                ''
              ),
              new Media(3, require('@/assets/15.jpeg'), 'image', '', ''),
            ],
            brief: 'الجمع بين الانزلاق والاسترخاء',
            description:
              'إركبوا على متن مزلقة الدروب و إستعدوا للتنقل من منعرج إلى منعرج. نضمن لك من خلال هذه الرحلة وابلا من الضحك و تناثر المياه، خصوصا و أن النزول من علو 8 متر سيكون مسليا مئة بالمئة',
            rules: [
              {
                id: 0,
                imgUrl: require('@/assets/rules/SimHeight.jpeg'),
                desc: 'الأشخاص فوق 140 سم أو الأطفال القادرين على السباحة',
              },
              {
                id: 1,
                imgUrl: require('@/assets/rules/Parent&Kid.jpeg'),
                desc: 'يمكنك مرافقة طفلك',
              },
              {
                id: 2,
                imgUrl: require('@/assets/rules/Preg.jpeg'),
                desc: 'ممنوع على المرأة الحامل',
              },
            ],
            stats: [
              {
                id: 0,
                name: 'سرعة',
                value: 50,
                color: 'var(--speed)',
              },
              {
                id: 1,
                name: 'أحاسيس قوية',
                value: 40,
                color: 'var(--thrill)',
              },
              {
                id: 2,
                name: 'استرخاء',
                value: 70,
                color: 'var(--relaxation)',
              },
            ],
          },
          {
            id: 7,
            name: 'مسبح الأمواج',
            medias: [
              new Media(
                0,
                require('@/assets/acqua-palace_piscine_a_vagues.jpeg'),
                'image',
                '',
                ''
              ),
              new Media(1, 'bAm80E5Rd9g', 'video', '', ''),
              new Media(
                2,
                require('@/assets/DSC_7414-1-3.jpeg'),
                'image',
                '',
                ''
              ),
              new Media(
                3,
                require('@/assets/DSC_7379-2.jpeg'),
                'image',
                '',
                ''
              ),
            ],
            brief: 'أمواج مثل أمواج البحر',
            description:
              'سواء كنتم من عشاق الهدوء أو الإثارة، ستجدون مبتغاكم. فكل الأذواق متوفرة. المهم أن تستمتعوا. بتجويف بعمق مترين و على مساحة 2000 متر متر مربع من المياه، يعد مسبح الأمواج أكبر مسبح في تونس. تنتظركم أيضا فضاءات أخرى لتتمتع كل العائلة قدر الإمكان بالمياه المنعشة. إستمتعوا أيضا بحمام شمس وسط فضاءاتنا الخضراء الممتدة على كامل المسبح. أترك إيقاع الموسيقى الفريد يسري في جسمك و يجعلك تهتز',
            rules: [
              {
                id: 0,
                imgUrl: require('@/assets/rules/Kid12Yo-2.jpeg'),
                desc: 'يجب مرافقة الأطفال دون 12 سنوات',
              },
            ],
            stats: [
              {
                id: 0,
                name: 'سرعة',
                value: 0,
                color: 'var(--speed)',
              },
              {
                id: 1,
                name: 'أحاسيس قوية',
                value: 50,
                color: 'var(--thrill)',
              },
              {
                id: 2,
                name: 'استرخاء',
                value: 80,
                color: 'var(--relaxation)',
              },
            ],
          },
          {
            id: 8,
            name: 'نهر بطيء',
            medias: [
              new Media(
                0,
                require('@/assets/acqua-palace_acqua-palace__K8A7666.jpeg'),
                'image',
                '',
                ''
              ),
              new Media(1, 'KEdNs2g_Eug', 'video', '', ''),
              new Media(2, require('@/assets/DSC_0032.jpeg'), 'image', '', ''),
            ],
            brief: 'الجولة الساحرة',
            description:
              'هذه الجولة على نهر بطول 200 متر محاط بالخضرة و المؤثرات المائية ستشعرك بالراحة و تمنح جسدك و عقلك إسترخاءا حقيقيا. هذه الجولة المذهلة على متن العوامة ليست هادئة كما يبدو، لذلك تمسكوا جيدا عند المرور و لتكن لديكم الجرأة على عبور نافوراتها المنعشة. إنها حقا طريقة مرحة للحصول على المتعة',
            rules: [
              {
                id: 0,
                imgUrl: require('@/assets/rules/Kid8Yo-2.jpeg'),
                desc: 'يجب مرافقة الأطفال دون 7 سنوات',
              },
            ],
            stats: [
              {
                id: 0,
                name: 'سرعة',
                value: 10,
                color: 'var(--speed)',
              },
              {
                id: 1,
                name: 'أحاسيس قوية',
                value: 0,
                color: 'var(--thrill)',
              },
              {
                id: 2,
                name: 'استرخاء',
                value: 85,
                color: 'var(--relaxation)',
              },
            ],
          },
          {
            id: 9,
            name: 'اكوا بيبي',
            medias: [
              new Media(
                0,
                require('@/assets/acqua-palace_DSC_0631.jpeg'),
                'image',
                '',
                ''
              ),
              new Media(
                1,
                require('@/assets/DSC_0042-1.jpeg'),
                'image',
                '',
                ''
              ),
              new Media(
                2,
                require('@/assets/DSC_9905-1.jpeg'),
                'image',
                '',
                ''
              ),
            ],
            brief: 'المنتزه المصغر لأطفالكم جديد 2020',
            description:
              'اكوا بيبي قصر المياه هو جنة الأطفال. به ألعاب ترفيه مصغرة ستدخل سرورا كبيرا على كل صغير. مع الميني فوم تضاف 5 ألعاب جديدة لموسم 2020. مع ’الميني دروب’, ’ميغا سلايد’, ’سفينة القراصنة’, ’الميني أخطبوط’ و ’الفطر’ و مسبح على مسافة 360 متر مربع و بعمق 0.4 متر. هي تجربة ساحرة للأطفال و مكان مناسب ليمرحوا بكل أمان',
            rules: [
              {
                id: 0,
                imgUrl: require('@/assets/rules/Kids-140.jpeg'),
                desc: 'للاطفال ما دون 140صم فقط',
              },
            ],
            stats: [
              {
                id: 0,
                name: 'سرعة',
                value: 20,
                color: 'var(--speed)',
              },
              {
                id: 1,
                name: 'أحاسيس قوية',
                value: 100,
                color: 'var(--thrill)',
              },
              {
                id: 2,
                name: 'استرخاء',
                value: 40,
                color: 'var(--relaxation)',
              },
            ],
          },
        ],
      },
      addRule: {
        fr:
          "*Un code vestimentaire et des consignes de securite supplementaires s'appliqueront. Ils seront affiches sur chaque attractions.",
        en:
          '*Additional dress code and safety guidelines will apply, They will be displayed on each attraction.',
        ar:
          '.سيتم تطبيق قواعد اللباس وإرشادات السلامة الإضافية، سيتم عرضها في كل الألعاب',
      },
    };
  },
  mutations: {},
  getters: {
    getAttractions(state, getters, rootState, rootGetters) {
      return state.attractions[rootGetters['lang/getCurrentLang']];
    },
    getAddRule(state, getters, rootState, rootGetters) {
      return state.addRule[rootGetters['lang/getCurrentLang']];
    },
  },
  actions: {},
};

export default attractions;
